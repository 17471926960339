.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 298px;
  height: 180px;
  background: linear-gradient(to right, rgba(89,32,203,1) 0%, rgba(124,9,190,1) 33%, rgba(121,3,181,1) 66%, rgba(116,0,184,1) 100%)
}

.mongo, .express, .react, .node {
  animation: spin 12s linear 0s infinite;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

.mongo:hover, .express:hover, .react:hover, .node:hover {
  animation-play-state: paused;
}

@keyframes spin {
  to {
    transform: rotate(360deg)
  }
}