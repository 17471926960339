.main {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  min-width: 298px;
  height: 180px;
  background: #222;
  overflow: hidden;
}

.pulse {
  text-align: center;
  color: white;
  font-weight: 700;
  font-size: 2rem;
  line-height: 50px;
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(158, 106, 143, 1);
  cursor: pointer;
  box-shadow: 0 0 0 rgba(158, 106, 143, 0.4);
  animation: pulse 3s infinite;
}

.pulse:nth-of-type(1) {
  animation-delay: .3s;
}

.pulse:nth-of-type(2) {
  animation-delay: .6s;
}

.pulse:nth-of-type(3) {
  animation-delay: .9s;
}

.pulse:nth-of-type(4) {
  animation-delay: 1.2s;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(158, 106, 143, 0.4);
  }
  70% {
    box-shadow: 0 0 0 150px rgba(158, 106, 143, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(158, 106, 143, 0);
  }
}