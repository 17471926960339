.icon {
  width: 25px;
  height: 25px;
  display: block;
  fill: #999;
  transition: all .5s;
  border: 2px solid #999;
  padding: 12px;
  border-radius: 50%;
  margin: 30px 0;
  cursor: pointer;
}

.icon {
  transition: all .5s;
}

.icon[data-icon='twitter']:hover {
  fill: #00acee;
  border-color: #00acee;
}

.icon[data-icon='instagram']:hover {
  fill: #e95950;
  border-color: #e95950;
}

.icon[data-icon='linkedin']:hover {
  fill: #0e76a8;
  border-color: #0e76a8;
}

.icon[data-icon='github']:hover {
  fill: #6f42c1;
  border-color: #6f42c1;
}

.icon[data-icon='mail']:hover {
  fill: var(--color-success);
  border-color: var(--color-success);
}