.main {
  width: 100%;
}

.title {
  color: white;
  font-size: 40px;
  text-align: center;
  letter-spacing: 1px;
  font-weight: 400;
  margin-top: 60px;
  margin-bottom: 40px;
  user-select: none;
}

.container {
  box-sizing: border-box;
  border: 1px solid white;
  width: calc(100% - 40px);
  max-width: 1400px;
  margin: 40px auto;
}

.mapBox {
  margin: 20px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  max-height: 60vh;
}

.mapContainer {
  --zoom: 1;
  width: calc(50% * calc(1 + var(--zoom)));
  height: 100%;
}

.mapContainer[data-zoom="2"] { --zoom: 2; }
.mapContainer[data-zoom="3"] { --zoom: 3; }
.mapContainer[data-zoom="4"] { --zoom: 4; }
.mapContainer[data-zoom="5"] { --zoom: 5; }
.mapContainer[data-zoom="6"] { --zoom: 6; }
.mapContainer[data-zoom="7"] { --zoom: 7; }
.mapContainer[data-zoom="8"] { --zoom: 8; }
.mapContainer[data-zoom="9"] { --zoom: 9; }
.mapContainer[data-zoom="10"] { --zoom: 10; }
.mapContainer[data-zoom="11"] { --zoom: 11; }
.mapContainer[data-zoom="12"] { --zoom: 12; }
.mapContainer[data-zoom="13"] { --zoom: 13; }
.mapContainer[data-zoom="14"] { --zoom: 14; }
.mapContainer[data-zoom="15"] { --zoom: 15; }

.map {
  stroke: black;
  stroke-width: .5px;
  width: 100%;
}

.legend {
  border-top: 1px solid white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
}

.controls {
  display: flex;
  justify-content: center;
  align-items: center;
}

.zoom {
  background-color: transparent;
  border: none;
  font-size: 25px;
  margin: 5px 20px;
  cursor: pointer;
  transition: font-size .3s, margin .3s;
  user-select: none;
}

.zoom:hover {
  font-size: 30px;
  margin: 1.75px 17.5px;
}

.subtitle {
  margin: 0;
  color: white;
  font-size: 16px;
  font-weight: 300;
  font-style: italic;
  user-select: none;
}

@media(max-width: 700px) {
  .legend {
    flex-direction: column;
    padding: 10px 0;
  }
  .controls {
    margin-bottom: 20px;
  }
  .subtitle {
    padding: 0 10px;
  }
}