.main {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 22px;
  align-items: center;
  justify-content: center;
}

.switch {
  margin: 50px auto;
}

.label {
  margin-left: 20px;
}

.fast .input:checked + div {
  background-color: steelblue;
}

.cheap .input:checked + div {
  background-color: teal;
}

.good .input:checked + div {
  background-color: salmon;
}

.good, .fast, .cheap {
  vertical-align: middle;
}