.main {
  max-width: 650px;
  margin: 0 auto;
  width: calc(100% - 40px);
}

.block {
  color: white;
  max-width: 650px;
  font-size: 18px;
  line-height: 28px;
  text-align: justify;
}

.block code {
  color: var(--color-code);
}

.link {
  color: var(--color-link);
}

.container {
  position: relative;
  display: flex;
  width: 20em;
  height: 3em;
  line-height: 3;
  background: #2c6e50;
  border-radius: .25em;
  margin: 25px auto;
}

.selector {
  appearance: none; 
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: #2c6e50;
  background-image: none;
  flex: 1;
  padding: 0 1.5em;
  color: white;
  cursor: pointer;
  font-size: 1rem;
}

.error {
  color: var(--color-error);
}

.selector::-ms-expand {
  display: none;
}

.container::after {
  content: '\25BC';
  color: #ccc;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 1em;
  background: #1c5e40;
  cursor: pointer;
  pointer-events: none;
  transition: .25s all ease;
}

.container:hover::after {
  color: white;
}

.concept {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}

.button {
  outline: none;
  margin: 20px;
  padding: 20px 40px;
  background: #2c6e50;
  border-radius: 50px;
  color: white;
  border: 1px solid #2c6e50;
  font-size: 1rem;
  box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.25);
  cursor: pointer;
}

.input {
  background: transparent;
  color: white;
  border: none;
  border-bottom: 2px solid white;
  padding: 5px 10px;
  width: calc(100% - 20px);
  box-sizing: border-box;
  font-size: 1rem;
  max-width: 500px;
  margin-bottom: 30px;
  margin-top: 30px;
  outline: none;
}

.button:hover {
  background: #4c8e70;
  border: 1px solid #4c8e70;
}

.button:disabled {
  background: gray;
  border: 1px solid gray;
  cursor: not-allowed;
}