.cover {
  width: 100%;
  min-width: 298px;
  height: 180px;
  box-sizing: border-box;
  background: #110c31;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signature {
  margin: 0;
  font-size: 6px;
  display: inline-block;
  animation: text-highlight 14s infinite alternate linear;
  font-family: 'Courier New', Courier, monospace;
  color: white;
  white-space: nowrap;
  font-size: 7px;
}

@keyframes text-highlight {
  0% { color: white; }
  10% { color: rgb(205, 123, 0); }
  20% { color: white; }
  30% { color: rgb(0, 225, 255); }
  40% { color: white; }
  50% { color: rgb(251, 0, 222); }
  60% { color: white; }
  70% { color: rgb(33, 231, 49); }
  80% { color: white; }
  90% { color: rgb(215, 32, 32); }
  100% { color: white; }
}