.main {
  max-width: 650px;
  margin: 0 auto;
  width: calc(100% - 40px);
}

.header {
  color: white;
  max-width: 650px;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: .3px;
  text-align: justify;
  margin-top: 45px;
}

.block {
  color: white;
  max-width: 650px;
  font-size: 18px;
  line-height: 28px;
  text-align: justify;
}

.block code {
  color: var(--color-code);
}

.block a {
  color: var(--color-link);
}

.section {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 40px 0;
}

.input {
  background: transparent;
  border: none;
  border-bottom: 2px solid white;
  color: white;
  padding-bottom: 6px;
  outline: none;
  font-size: 20px;
  flex-grow: 1;
}

.button {
  background: transparent;
  border: 1px solid white;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 20px 21px;
  outline: none;
  box-shadow: 0px 0px 4px 2px rgba(255,255,255,.5);
  transition: box-shadow .3s;
  cursor: pointer;
  margin-left: 50px;
}

.button:active {
  box-shadow: 2px 2px 6px 4px rgba(255,255,255,1);
}

.buttonIcon {
  fill: white;
  width: 50px;
  height: 50px;
}

@media(max-width: 800px) {
  .section {
    flex-direction: column;
  }
  .input {
    align-self: stretch;
    width: calc(100% - 40px);
    margin: 0 auto;
  }
  .button {
    margin-top: 30px;
    margin-left: 0;
  }
  .main code {
    width: calc(100% - 40px);
  }
}