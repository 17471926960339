.grid {
  display: flex;
  flex-wrap: wrap;
  margin: 90px auto 10px;
}

.cell {
  border: 1px solid rgba(255, 255, 255, .3);
  flex-shrink: 0;
  box-sizing: border-box;
  cursor: pointer;
}

.controls {
  max-width: 600px;
  margin: 0 auto 50px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.recent {
  display: flex;
  flex-direction: column;
  margin: 40px 5vw 20px;
  align-items: flex-start;
}

.edit {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.colorPicker {
  width: 30px;
  height: 30px;
  margin: 20px;
  box-sizing: border-box;
}

.recentColor {
  width: 18px;
  height: 18px;
  border: 1px solid white;
  border-radius: 3px;
  margin: 6px 12px;
  cursor: pointer;
}

.button {
  background: transparent;
  border: 1px solid white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  box-shadow: 0px 0px 0px 2px rgba(155,155,155,0.35);
  margin: 20px;
  outline: none;
  transition: background .5s;
  cursor: pointer;
}

.button[data-status='active'] {
  background: white;
}

.icon {
  transition: fill .5s;
  fill: white;
}

.button[data-status='active'] .icon {
  fill: var(--color-dark);
}

.input {
  user-select: none;
}

.inputContent {
  display: flex;
  color: white;
}

.inputButton {
  border: 1px solid white;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 1.5rem;
  cursor: pointer;
}

.inputValue {
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  height: 30px;
  flex-grow: 1;
  line-height: 30px;
  text-align: center;
  font-size: 1.25rem;
  padding: 0 25px;
  min-width: 100px;
}

.inputLabel {
  color: #e2e2e2;
  margin: 0;
  font-size: .85rem;
}

.recentTitle {
  color: white;
  margin: 0;
}

.recentColors {
  display: flex;
  flex-wrap: wrap;
  max-width: 450px;
  width: 90%;
}

@media(max-width: 550px) {
  .input {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .row {
    flex-direction: column;
  }
}