@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');

.main {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 100px auto 160px;
  text-align: center;
  width: 100%;
}

.text {
  animation: neon 1.5s ease-in-out infinite alternate;
  color: white;
  display: inline-block;
  font-family: Monoton;
  font-size: 7em;
  margin: 0;
  max-width: 80%;
  text-decoration: none;
  transition: all 0.5s;
  word-spacing: 25px;
}

.block {
  color: white;
  width: calc(100% - 40px);
  max-width: 650px;
  font-size: 18px;
  line-height: 28px;
  text-align: justify;
  margin: 20px auto;
}

.block code {
  color: var(--color-code);
}

.text:hover {
  animation: none;
  color: #FF1177;
}

@keyframes neon {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #FF1177, 0 0 70px #FF1177, 0 0 80px #FF1177, 0 0 100px #FF1177, 0 0 150px #FF1177;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #FF1177, 0 0 35px #FF1177, 0 0 40px #FF1177, 0 0 50px #FF1177, 0 0 75px #FF1177;
  }
}

@media (max-width: 650px) {
  .main {
    width: 100%;
  }
  .text {
    font-size: 3.5em;
    word-spacing: 15px;
  }
}