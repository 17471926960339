.main {
  color: white;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  font-family: 'Press Start 2P', cursive;
  font-size: 15px;
  line-height: 25px;
}

.block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 6px 0 2.2em;
}

.input {
  background: transparent;
  outline: none;
  border: none;
  border-bottom: 2px solid #ffffff40;
  margin: 0 8px;
  padding: 2px 14px;
  color: var(--color-shakers);
  font-size: 1em;
  font-family: inherit;
  flex-grow: 1;
  max-width: 100%;
  margin: 0;
}

textarea.input {
  border: 2px solid #ffffff40;
  resize: vertical;
  border-radius: 4px;
  min-height: 200px;
  padding: 12px;
}

.text {
  margin: 0 0 .8em;
}

.text:last-of-type {
  margin-bottom: 60px;
}

.block .text {
  margin: 0;
  margin-bottom: 30px;
}

.submit {
  align-self: flex-end;
  font-family: inherit;
  background: transparent;
  color: white;
  font-size: 12px;
  border-radius: 12px;
  padding: 12px 40px;
  cursor: pointer;
  border: 1px solid white;
  transition: color .6s, border-bottom .6s;
}

.input:invalid:not(:focus):not(:placeholder-shown) {
  border-bottom: 2px solid var(--color-error);
  color: var(--color-error);
}

.main:invalid .submit {
  pointer-events: none;
  color: #888;
  border: 1px solid #888;
  cursor: not-allowed;
}

.interactive {
  color: var(--color-shakers);
  cursor: pointer;
}

.link {
  color: var(--color-link);
  text-decoration: none;
}

.interactive:hover {
  text-decoration: underline;
}

.text strong {
  color: var(--color-shakers);
}

.detail {
  font-size: 10px;
  display: inline-block;
  color: #c2c2c2
}

.block .text  {
  margin: 0;
}

@media(max-width: 450px) {
  .main {
    font-size: 13px;
  }
}