.main {
  max-width: 650px;
  margin: 0 auto;
  width: calc(100% - 40px);
}

.block {
  color: white;
  max-width: 650px;
  font-size: 18px;
  line-height: 35px;
  text-align: justify;
}

.block + .block {
  margin-top: 40px;
}

.title {
  margin-top: 100px;
  font-size: 50px;
  text-align: center;
  letter-spacing: 40px;
  font-size: 500;
}

.logos {
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 600px;
  margin: 80px auto 35px;
  width: 90%;
}

.mongo {
  cursor: pointer;
  animation-name: bounce;
  animation-duration: 1.0s;
  animation-iteration-count: infinite;
}

.express {
  cursor: pointer;
  animation-name: bounce;
  animation-duration: 1.1s;
  animation-iteration-count: infinite;
}

.react {
  cursor: pointer;
  animation-name: bounce;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
}

.node {
  cursor: pointer;
  animation-name: bounce;
  animation-duration: 1.3s;
  animation-iteration-count: infinite;
}

.team {
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 600px;
  width: 90%;
  margin: 15px auto;
}

.member {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 30px 0;
}

.thought {
  margin-bottom: 20px;
}

.explain {
  border: 1px solid white;
  border-radius: 4px;
  padding: 32px 24px;
  margin: 30px auto;
  color: white;
}

.explain[data-status="placeholder"] {
  text-align: center;
}

.explain[data-status="active"]:first-letter {
  font-size: 1.75rem;
  letter-spacing: 2px;
}

@media(max-width: 400px) {
  .team {
    flex-wrap: wrap;
  }
  .member {
    min-width: 120px;
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-12px);
  }
}