.main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.canvas,
.controls {
  --canvas-width: calc(100vw - 50px);
}

.canvas {
  margin: 5px;
  border: 1px solid black;
  border-radius: 3px;
  width: var(--canvas-width);
  height: calc(var(--canvas-width) / 2.7);
  box-sizing: border-box;
  box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.35);
}

.controls {
  width: var(--canvas-width);
  margin-top: 25px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.marker {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 10px;
  cursor: pointer;
}

.eraser,
.clear ,
.download {
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 10px;
  border: 1px solid white;
  color: white;
  text-align: center;
  line-height: 20px;
}

.background {
  width: 20px;
  height: 20px;
  border: 1px solid black;
  border-radius: 50%;
  margin: 0 10px;
  text-align: center;
  background-color: white;
}

.background[data-type="horizontal"] {
  background-image: linear-gradient(black 0%, transparent 40%);
  background-size: 3px 3px;
}

.background[data-type="vertical"] {
  background-image: linear-gradient(90deg, black 0%, transparent 40%);
  background-size: 3px 3px;
}

.background[data-type="grid"] {
  background-image: linear-gradient(90deg, black 0%, transparent 40%), linear-gradient(black 0%, transparent 40%);
  background-size: 3px 3px;
}