.cover {
  width: 100%;
  min-width: 298px;
  height: 180px;
  padding: 65px 0;
  box-sizing: border-box;
  background: magenta;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  color: white;
  font-weight: 900;
  font-size: 35px;
  animation: flipper 3s infinite;
}

@keyframes flipper {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(-180deg);
  }
  50% {
    transform: rotate(-180deg);
  }
  75% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }

}