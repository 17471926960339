.cover {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
  width: 100%;
  min-width: 298px;
  background: linear-gradient(to right, #536976, #292e49);

}

.typer {
  max-width: 60%;
  max-height: 60%;
  fill: #efb642;
}

.content {
  opacity: .5;
  animation: appearingText .95s infinite alternate;
}

@keyframes appearingText {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  90% {
    opacity: 0.05;
  }
  100% {
    opacity: 0.05;
  }
}