.main {
  max-width: 650px;
  margin: 0 auto;
  width: calc(100% - 40px);
}

.block {
  color: white;
  max-width: 650px;
  font-size: 18px;
  line-height: 28px;
  text-align: justify;
}

.header {
  color: white;
  max-width: 650px;
  font-size: 25px;
  line-height: 32px;
  letter-spacing: .3px;
  text-align: justify;
  margin-top: 45px;
}

.block code {
  color: var(--color-code);
}

.block strong {
  color: var(--color-shakers);
}
