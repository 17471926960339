.content {
  width: 100%;
  min-width: 298px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-dark);
}

.grid {
  --grid-size: repeat(2, 40px);
  display: grid;
  grid-template-rows: var(--grid-size);
  grid-template-columns: var(--grid-size);
  touch-action: none;
}

.cell {
  outline: 1px solid #efefef;
}