.cover {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
  width: 100%;
  min-width: 298px;
  background: var(--color-shakers-secondary);
}

.logo {
  max-width: 240px;
  fill: var(--color-shakers);
}

.logo [data-letter="S"] {
  animation: shakersFade 6s infinite;
}

.logo [data-letter="H"] {
  animation: shakersFade 5.5s infinite linear;
}

.logo [data-letter="A"] {
  animation: shakersFade 5.9s infinite;
}

.logo [data-letter="K"] {
  animation: shakersFade 6.7s infinite;
}

.logo [data-letter="E"] {
  animation: shakersFade 5.1s infinite ease-in;
}

.logo [data-letter="R"] {
  animation: shakersFade 6.2s infinite;
}

.logo [data-letter="Z"] {
  animation: shakersFade 6.7s infinite ease-in-out;
}

@keyframes shakersFade {
  0% {
    opacity: 0;
  }
  30% {
    opacity: .8;
  }
  70% {
    opacity: .8;
  }
  100% {
    opacity: 0;
  }
}