.main {
  width: calc(100% - 60px);
  margin: 0 auto;
  max-width: 1200px;
  padding-top: 80px;
}

.title {
  text-align: center;
  color: white;
  width: calc(100% - 20px);
  max-width: 850px;
  margin: 0px auto 60px;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: .35px;
}

.intro {
  margin-bottom: 60px;
}

.block {
  color: white;
  width: calc(100% - 20px);
  max-width: 850px;
  margin: 12px auto 30px;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: .35px;
}

.coolLink {
  color: var(--color-link);
  font-weight: 700;
}

.category {
  margin: 30px 0;
}

.categoryTitle {
  color: white;
  text-transform: uppercase;
  padding: 0 20px 12px;
  border-bottom: 1px solid white;
  font-size: 24px;
}

.categoryTitle::first-letter {
  font-size: 36px;
}

.categoryContent {
  display: flex;
  flex-wrap: wrap;
}

.link {
  width: 150px;
  height: 150px;
  box-sizing: border-box;
  padding: 20px;
  margin: 20px;
  border: 1px solid white;
  border-radius: 4px;
  color: white;
  fill: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-decoration: none;
  transition: all .5s;
  text-align: center;
}

.link:hover,
.link[data-color="yellow"]:hover {
  border: 1px solid var(--color-shakers);
  color: var(--color-shakers);
  fill: var(--color-shakers);
  background: rgba(255, 221, 51, .1);
}

.link[data-color="orange"]:hover {
  border: 1px solid var(--color-warning);
  color: var(--color-warning);
  fill: var(--color-warning);
  background: rgba(255, 127, 80, .1);
}

.link[data-color="pink"]:hover {
  border: 1px solid var(--color-code);
  color: var(--color-code);
  fill: var(--color-code);
  background: rgba(255, 20, 147, .1);
}

.link[data-color="blue"]:hover {
  border: 1px solid var(--color-info);
  color: var(--color-info);
  fill: var(--color-info);
  background: rgba(0, 191, 255, .1);
}

.link[data-color="green"]:hover {
  border: 1px solid var(--color-success);
  color: var(--color-success);
  fill: var(--color-success);
  background: rgba(0, 250, 154, .1);
}

.link-text {
  margin-bottom: 0;
}

@media(max-width: 600px) {
  .link {
    width: 100px;
    height: 100px;
    padding: 10px;
    margin: 10px;
    font-size: 12px;
  }
}