.main {
  width: 100%;
  min-width: 298px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, #0f2027, #203a43, #2c5364);
}

.letter {
  font-weight: 700;
  color: white;
  font-size: 60px;
}