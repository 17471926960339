.main {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 40px;
}

.rulesList {
  color: white;
  max-width: 650px;
  font-size: 18px;
  line-height: 28px;
  text-align: justify;
}

.congratulations {
  text-align: center;
  width: 90%;
  margin: 0 auto;
  color: white;
  font-size: 22px;
}

.board {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 540px;
  height: 540px;
  outline: none;
}

.cell {
  color: white;
  border: .5px solid white;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: calc(100% / 9);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  min-height: 56px;
}

.cell[data-row="1"] {
  border-top-width: 6px;
}

.cell[data-row="3"],
.cell[data-row="6"] {
  border-bottom-width: 5px 
}

.cell[data-row="9"] {
  border-bottom-width: 6px;
}

.cell[data-column="1"] {
  border-left-width: 6px;
}

.cell[data-column="3"],
.cell[data-column="6"] {
  border-right-width: 5px 
}

.cell[data-column="9"] {
  border-right-width: 6px;
}

.cell[data-type='base'] {
  font-weight: bold;
  color: #d2d2d2;
  background-color: rgba(255, 255, 255, .1);
}

.cell[data-type='base'][data-highlight='hinted'] {
  background-color: var(--color-info);
}

.cell[data-selection='selected'] {
  background-color: var(--color-warning);
}

.cell[data-type='user'][data-highlight='correct'][data-status='data'][data-selection='unselected'] {
  background: var(--color-success);
}

.cell[data-type='user'][data-highlight='incorrect'][data-status='data'][data-selection='unselected'] {
  background: var(--color-error);
}

.cell[data-type='user'][data-status='data'][data-selection='unselected'] {
  background: var(--color-code);
}

@media(max-width: 800px) {
  .main {
    flex-direction: column;
  }
}

@media(max-width: 580px) {
  .board {
    max-width: 360px;
    height: 360px;
  }
  .cell {
    min-height: 36px;
    font-size: 20px;
  }
  .button {
    padding: 8px 18px;
  }
  .spinner {
    margin: 125px;
    max-width: 360px;
  }
}

@media(max-width: 415px) {
  .board {
    max-width: 270px;
    height: 270px;
  }
  .cell {
    min-height: 26px;
    font-size: 16px;
  }
  .button {
    padding: 10px 20px;
  }
  .cell[data-row="1"] {
    border-top-width: 4px;
  }
  
  .cell[data-row="3"],
  .cell[data-row="6"] {
    border-bottom-width: 3px 
  }
  
  .cell[data-row="9"] {
    border-bottom-width: 4px;
  }
  
  .cell[data-column="1"] {
    border-left-width: 4px;
  }
  
  .cell[data-column="3"],
  .cell[data-column="6"] {
    border-right-width: 3px 
  }
  
  .cell[data-column="9"] {
    border-right-width: 4px;
  }
  .spinner {
    max-width: 270px;
    margin: 160px;
  }
}

.spinner {
  font-size: 10px;
  margin: 215px;
  text-indent: -9999em;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background: #ffffff;
  background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  animation: sudokuSpin 1.4s infinite linear;
  transform: translateZ(0);
  max-width: 540px;
}

.spinner:before {
  width: 50%;
  height: 50%;
  background: #ffffff;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.spinner:after {
  background: var(--color-dark);
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@keyframes sudokuSpin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}