.backdrop {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .75);
}

.content {
  color: white;
  border: 1px solid black;
  background: #282C37;
  width: calc(100% - 80px);
  max-width: 750px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 3px 2px 2px 3px rgba(40,44,55,0.35);
}