.main {
  max-width: 650px;
  margin: 0 auto;
  width: calc(100% - 40px);
}

.header {
  color: white;
  max-width: 650px;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: .3px;
  text-align: justify;
  margin-top: 85px;
}

.block {
  color: white;
  max-width: 650px;
  font-size: 18px;
  line-height: 28px;
  text-align: justify;
}

.result {
  font-family: 'Courier New', 'Courier', monospace;
  font-size: 6px;
  line-height: 6px;
  letter-spacing: -0.7px;
  color: white;
  text-align: center;
  display: inline-block;
  transform: translateX(calc(-50% + 325px));
}

.video {
  display: block;
  margin: 25px auto;
}

.row {
  display: block;
}

.status {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.status[data-status="OK"] .status-button {
  border: 5px solid #c2c2c2;
  color: #c2c2c2;
  box-shadow: 0 0px 7px 5px rgba(255, 69, 00, .1);
}

.button {
  font-size: 25px;
  padding: 33px 20px;
  background: transparent;
  border: 5px solid white;
  color: white;
  border-radius: 50%;
  box-shadow: 0 0px 7px 5px rgba(255, 255, 255, .1);
  cursor: pointer;
  outline: none;
  min-width: 115px;
}

.status[data-status="OK"] .text {
  border: 5px solid var(--color-success);
  color: var(--color-success);
}

.status[data-status="Pending"] .text {
  border: 5px solid var(--color-warning);
  color: var(--color-warning);
}

.status[data-status="Error"] .text {
  border: 5px solid var(--color-error);
  color: var(--color-error);
}

.text {
  font-size: 45px;
  padding: 10px 40px 10px 90px;
  background: transparent;
  border-radius: 6px;
  position: relative;
}

.text::before {
  content: '·';
  font-size: 300px;
  position: absolute;
  line-height: 60px;
  top: 0;
  left: 10px;
}

@media(max-width: 950px) {
  .result {
    font-size: 4px;
    line-height: 4px;
    letter-spacing: -0.4px;
  }
}

@media(max-width: 695px) {
  .result {
    text-align: center;
    transform: none;
    display: block;
  }
}

@media(max-width: 660px) {
  .result {
    font-size: 3px;
    line-height: 3px;
    letter-spacing: -0.3px;
  }
}

@media(max-width: 520px) {
  .result {
    font-size: 2px;
    line-height: 2px;
    letter-spacing: -0.2px;
  }
  .status {
    flex-direction: column;
  }
}