.main {
  background-color: var(--color-dark);
  width: 100%;
  min-height: 100vh;
}

.header {
  max-width: 90%;
  margin: 0 auto;
}

.title {
  color: white;
  font-size: 48px;
  text-align: center;
  letter-spacing: 1px;
  font-weight: 400;
  margin-top: 60px;
  margin-bottom: 10px;
}

.subtitle {
  color: white;
  font-size: 32px;
  text-align: center;
  letter-spacing: 1px;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 60px;
  font-style: italic;
}

.cards {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 1360px;
}

@media(max-width: 1400px) {
  .cards {
    width: 1020px;
  }
}

@media(max-width: 1060px) {
  .cards {
    width: 680px;
  }
}

@media(max-width: 740px) {
  .cards {
    width: 340px;
  }
}

@media(max-width: 340px) {
  .cards {
    width: 300px;
  }
  .cards > div {
    max-width: 260px;
  }
}