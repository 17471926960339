.main {
  max-width: 650px;
  width: 90%;
  margin: 0 auto;
}

.block {
  color: white;
  max-width: 650px;
  font-size: 18px;
  line-height: 28px;
  text-align: justify;
}

.block code {
  color: var(--color-code);
}

.buttonArea {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
}

.button {
  -webkit-appearance: none;
  background: transparent;
  border: 1px solid white;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  outline: none;
}

.link {
  color: var(--color-link);
}

.modal {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.video {
  max-width: 100%;
}

.credit {
  margin: 20px 0;
}