.main {
  width: calc(100% - 80px);
  max-width: 600px;
  margin: 0 auto 120px;
}

.title {
  color: white;
  font-size: 48px;
  text-align: center;
  letter-spacing: 1px;
  font-weight: 400;
  margin-top: 60px;
  margin-bottom: 40px;
  user-select: none;
}

.unavailable {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.available {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.subtitle {
  color: white;
  max-width: 650px;
  font-size: 32px;
  text-align: center;
}

.result {
  padding: 16px 28px;
  width: 100%;
  position: relative;
}

.result[data-status="success"] {
  background-color: var(--color-success-dark);
}

.result[data-status="error"] {
  background-color: var(--color-error);
}

.resultClose {
  position: absolute;
  top: 9px;
  right: 12px;
  cursor: pointer;
  width: 18px;
  height: 18px;
}

.resultClose::before {
  content: " ";
  width: 24px;
  height: 3.5px;
  display: block;
  position: absolute;
  left: 0;
  transform: rotate(45deg);
  transform-origin: 0% 50%;
  transition: background-color 0.3s ease-in-out;
}

.resultClose::after {
  content: " ";
  width: 24px;
  height: 3.5px;
  display: block;
  position: absolute;
  right: 1px;
  transform: rotate(-45deg);
  transform-origin: 100% 50%;
  transition: background-color 0.3s ease-in-out;
}

.result[data-status="success"] .resultClose:not(:hover)::before,
.result[data-status="success"] .resultClose:not(:hover)::after {
  background-color: rgb(33, 170, 55);
}

.result[data-status="error"] .resultClose:not(:hover)::before,
.result[data-status="error"] .resultClose:not(:hover)::after {
  background-color: rgb(180, 71, 64);
}

.resultClose:hover::before,
.resultClose:hover::after {
  background-color: #fff;
}

.resultMessage {
  color: white;
  font-size: 22px;
  margin: 12px 0;
}

.resultMessage a {
  color: var(--color-link);
  text-decoration: none;
  font-weight: bold;
}

.resultMessage a:hover {
  text-decoration: underline;
}

.resultMessage code {
  font-size: 16px;
  letter-spacing: -.3px;
  font-weight: bold;
}

.block {
  color: white;
  max-width: 650px;
  font-size: 18px;
  line-height: 35px;
  text-align: justify;
}

.block a {
  color: var(--color-link);
  font-weight: bold;
}

.block code {
  color: var(--color-code);
}

.block strong {
  color: var(--color-shakers);
}

.button {
  color: white;
  text-decoration: none;
  border: 1px solid white;
  padding: 12px 20px;
  border-radius: 5px;
  font-size: 22px;
  cursor: pointer;
  background-color: transparent;
  transition: all .5s;
  margin-top: 20px;
}

.button[data-status="loading"] {
  background: linear-gradient(to left, #000, #aaa);
  background-size: 200% 200%;
  animation: gradient-animation 2.4s ease infinite;
}

.button:hover {
  background-color: rgba(255, 255, 255, .2);
  font-size: 26px;
}

.button:disabled {
  cursor: not-allowed;
  opacity: .4;
}

.button:disabled:hover {
  font-size: 22px;
  background-color: transparent;
}

.buttonLink {
  color: white;
  text-decoration: none;
  border: 1px solid white;
  padding: 12px 20px;
  border-radius: 5px;
  font-size: 22px;
  cursor: pointer;
  transition: all .5s;
}

.buttonLink:hover {
  background-color: rgba(255, 255, 255, .2);
  font-size: 26px;
}

.metamask {
  transition: all 500ms;
  width: 300px;
  height: 300px;
  max-width: 80%;
}

.metamask[data-status="disabled"] {
  filter: grayscale(1);
}

.metamask g {
  fill: var(--color-layer);
  stroke: var(--color-layer);
}

.metamask [data-group="0"] {
  --color-layer: #E2761B;
}

.metamask [data-group="1"] {
  --color-layer: #E4761B;
}

.metamask [data-group="2"] {
  --color-layer: #D7C1B3;
}

.metamask [data-group="3"] {
  --color-layer: #233447;
}

.metamask [data-group="4"] {
  --color-layer: #CD6116;
}

.metamask [data-group="5"] {
  --color-layer: #E4751F;
}

.metamask [data-group="6"] {
  --color-layer: #F6851B;
}

.metamask [data-group="7"] {
  --color-layer: #C0AD9E;
}

.metamask [data-group="8"] {
  --color-layer: #161616;
}

.metamask [data-group="9"] {
  --color-layer: #763D16;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}