.main {
  width: 100%;
  box-sizing: border-box;
  max-width: 450px;
  padding: 20px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.content {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.profile {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.image {
  border: 1px solid black;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.25);
}

.name {
  color: white;
  font-weight: 500;
  font-size: 32px;
  margin: 20px 0 20px;
}

.header {
  color: white;
  font-weight: 400;
  font-style: italic;
  font-size: 18px;
  margin: 0;
}

.social {
  margin: 20px 0 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.icon {
  flex: 0 0 33%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
}

@media(min-width: 600px) {
  .main {
    height: 100vh;
  }
  .content {
    border-radius: 12px;
    border: 1px solid white;
    align-self: center;
  }
}