@import url('https://fonts.googleapis.com/css2?family=Nosifer&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap');

.main {
  max-width: 650px;
  margin: 0 auto;
  width: calc(100% - 40px);
  --blood-color: #bb0a1e;
  --paper-color: #feff9c;
}

.block {
  color: white;
  max-width: 650px;
  font-size: 18px;
  line-height: 28px;
  text-align: justify;
}

.storyblock {
  color: white;
  margin: 0 auto;
  font-style: italic;
  font-weight: 400;
  max-width: 540px;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: .27px;
  text-align: justify;
}

.block a {
  color: var(--color-link);
  font-weight: bold;
}

.example {
  background-color: var(--paper-color);
  background-image: linear-gradient(to bottom,transparent 29px, rgb(229, 230, 140) 1px),
                    linear-gradient(90deg, transparent 0px, transparent 24px, red 25px, transparent 25px, transparent 29px, red 30px, transparent 30px);; 
  background-size: 100% 30px;
  box-shadow:3px 3px 3px rgba(254,255,156,.2),0px 0px 6px rgba(254,255,156,.2);
  min-height: 400px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 15px 50px;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  width: 80%;
  max-width: 540px;
  margin: 20px auto;
}

.exampleText[data-type='creepy'] {
  font-family: 'Nosifer', cursive;
  font-size: 3rem;
  color: var(--blood-color);
}

.exampleText[data-type='adorable'] {
  font-family: 'Reenie Beanie', cursive;
  font-size: 4rem;
  max-width: 350px;
  margin: 0 auto;
  color: var(--color-code);
}

.separator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
}

.separatorDot {
  margin: 10px;
  border: 1px solid rgba(255, 255, 255, .2);
  background-color: rgba(255, 255, 255, .05);
  width: 7px;
  height: 7px;
  border-radius: 50%;
}