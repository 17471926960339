.cover {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 298px;
  height: 180px;
  background: #245a29
}

.logo {
  animation: flip-vertical-fwd 5s ease-in-out infinite alternate both;
}

@keyframes flip-vertical-fwd {
  0% {
    transform: translateZ(0) rotateY(0);
  }
  100% {
    transform: translateZ(160px) rotateY(180deg);
  }
}