.title {
  font-size: 2.5em;
  color: white;
}

.main {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: calc(100% - 80px);
  margin: 0 auto;
}

.info {
  flex: 0 0 calc(100% - 40px);
  margin: 0 20px 60px;
}

.office {
  flex: 1 0 calc(40% - 40px);
  margin: 0 20px;
}

.team {
  flex: 1 0 calc(40% - 40px);
  margin: 0 20px;
}

@media(max-width: 991px) {
  .office {
    flex: 1 0 calc(100% - 40px);
    margin: 0 20px 40px;
  }
  .team {
    flex: 1 0 calc(100% - 40px);
    margin: 0 20px 40px;
  }
}