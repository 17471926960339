.link {
  text-decoration: none;
}

.logo {
  cursor: pointer;
  height: 35px;
  margin: 15px;
  opacity: .6;
  transition: transform .5s, opacity .5s;
  width: auto;
}

.logo:hover {
  opacity: 1;
  transform: scale(1.15);
}