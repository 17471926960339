.main {
  max-width: 650px;
  margin: 0 auto;
  width: calc(100% - 40px);
}

.block {
  color: white;
  max-width: 650px;
  font-size: 18px;
  line-height: 28px;
  text-align: justify;
}

.block code {
  color: var(--color-code);
}

.link {
  color: var(--color-link);
}

.texture {
  padding: 20px;
}

.textureText {
  color: transparent;
  text-transform: uppercase;
  background: url(./resources/background.jpg);
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 72px;
  font-weight: bold;
  margin: 0;
  text-align: center;
}

.gradient {
  padding: 20px;
}

.gradientText {
  color: transparent;
  text-transform: uppercase;
  background: linear-gradient(0deg, rgba(34, 193, 195, 1) 0%, rgba(253, 187, 45, 1) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 72px;
  font-weight: bold;
  margin: 0;
  text-align: center;
}

.hoverable {
  padding: 20px;
}

.hoverableText {
  color: transparent;
  text-transform: uppercase;
  background: linear-gradient(to right, #fff, #fff, #555, #555);
  background-size: 400%, 400%;
  background-position-x: 100%;
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 50px;
  font-weight: bold;
  transition: all 2s ease;
  text-align: center;
  margin: 0;
}

.hoverableText:hover {
  background-position-x: 0%;
}

@media(max-width: 360px) {
  .textureText {
    font-size: 54px;
  }
  .gradientText {
    font-size: 54px;
  }
}