.main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 80px 0;
  width: calc(100% - 40px);
  max-width: 600px;
  margin: 0 auto;
}

.title {
  color: white;
  font-size: 32px;
  border-bottom: 1px solid white;
  padding-bottom: 2px;
  width: 100%;
}

.avatar {
  perspective: 1000px;
  border: 1px solid black;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  box-shadow: -6px 6px 5px 0px rgba(0, 0, 0, 0.75);
}

.avatar[data-status='turned'] .flip {
  transform: rotateY(180deg);
}

.flip {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}

.avatarFront {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--color-dark);
}

.avatarBack {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotateY(180deg);
  width: 100%;
  background: var(--color-dark);
}

.intro {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 25px;
}

.text {
  color: white;
  font-size: 22px;
  margin-left: 25px;
}

.textContent {
  margin: 5px 0;
}

.jobs {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 0 25px;
}

.job {
  display: flex;
  align-items: center;
  justify-content: center;
}

.social {
  padding: 0 25px;
}

.jobConnector {
  height: 60px;
  width: 2px;
  background: white;
  flex-shrink: 1;
  margin: 0 40px;
}

.jobLogoContainer {
  width: 80px;
  height: 80px;
  border: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 22px;
  box-sizing: border-box;
  border-radius: 50%;
  flex-shrink: 0;
  transition: all .4s;
  cursor: pointer;
}

.jobLogoContainer:hover {
  padding: 18px;
  background: rgba(0, 0, 0, .5);
}

.jobLogo {
  max-width: 100%;
  max-height: 100%;
}

.jobDescription {
  margin-left: 20px;
  border: 1px solid white;
  position: relative;
  padding: 5px 20px;
  border-radius: 4px;
}

.jobDescription::before {
  content: " ";
  display: block;
  width: 14px;
  height: 14px;
  border-top: 1px solid white;
  border-left: 1px solid white;
  position: absolute;
  top: calc(50% - 3px);
  left: -8px;
  transform: rotate(-45deg);
  background: var(--color-dark);
}

.jobCompany {
  color: white;
  font-weight: 700;
  font-size: 20px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.jobRole {
  margin: 8px 0;
  color: white;
  text-transform: uppercase;
  font-weight: 400;
  font-style: italic;
  font-size: 14px;
}

.socialElement {
  display: flex;
  align-items: center;
  fill: white;
  margin-bottom: 20px;
}

.socialDescription {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: white;
  margin-left: 20px;
}

.socialEntry {
  font-size: 22px;
  margin: 0;
}

.socialDetail {
  font-size: 14px;
  font-style: italic;
  margin: 0;
  letter-spacing: .5px;
  color: #aaa;
}

.stack {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  width: calc(100% - 50px);
  margin: 0 auto;
}

.stackIcon {
  width: 80px;
  cursor: pointer;
  transition: all 1s;
}

.stackIcon:hover {
  transform: scale(1.25);
}

.stackIcon[data-icon="react"] {
  fill: #61DBFB
}

.stackIcon[data-icon="nodejs"] {
  fill: #68A063
}

.stackIcon[data-icon="mongodb"] {
  fill: #4DB33D;
}

.stackIcon[data-icon="aws"] {
  fill: #eeba37;
}

.bio {
  padding: 0 25px;
}

.bioElement {
  display: flex;
  align-items: center;
  color: white;
  fill: white;
  font-size: 18px;
}

.bioIcon {
  width: 30px;
  margin-right: 20px;
}

.hexagon, .hexagonContent {
  --hexagon-width: 90px;
}

.hexagon {
  position: relative;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  width: var(--hexagon-width);
  height: calc(var(--hexagon-width) / 0.866 );
  background: white;
  transition: all 1s;
  margin: 10px 15px 0;
}

.hexagonContent {
  position: absolute;
  top: 3px;
  left: 3px;
  bottom: 3px;
  right: 3px;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  width: calc(var(--hexagon-width) - 6px);
  height: calc(calc(var(--hexagon-width) - 6px) / 0.866 );
  transition: all 2s;
  background: var(--color-dark);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.hobbies {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.hobbiesIcon {
  fill: white;
  width: 60px;
  height: 35px;
  margin-bottom: 5px;
  transition: all 1s;
}

.hobbiesIcon > svg {
  width: 100%;
  height: 100%;
}

.hobbiesDescription {
  color: white;
  margin: 0;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: .4px;
  transition: all 1s;
}

.hexagon:hover {
  background: var(--color-shakers);
}

.hexagon:hover .hobbiesIcon {
  fill: var(--color-shakers);
}

.hexagon:hover .hobbiesDescription {
  color: var(--color-shakers);
}

@media(max-width: 600px) {
  .intro {
    padding: 0 10px
  }
  .avatar {
    width: 90px;
    height: 90px;
  }
  .text {
    font-size: 18px;
  }
  .title {
    font-size: 28px;
  }
  .bioIcon {
    width: 25px;
  }
  .bioText {
    font-size: 15px;
  }
  .socialEntry {
    font-size: 18px;
  }
  .socialDetail {
    font-size: 13px;
  }
  .stackIcon {
    width: 60px;
  }
  .jobCompany {
    font-size: 18px;
  }
  .jobRole {
    font-size: 13px;
  }
}

@media(max-width: 400px) {
  .intro {
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  .text {
    margin: 20px 0 0 0;
    text-align: center;
  }
  .title {
    font-size: 24px;
  }
  .stackIcon {
    margin: 10px 0;
    flex: 0 0 35%;
  }
  .jobs {
    padding: 0 5px;
  }
  .jobLogoContainer {
    width: 50px;
    height: 50px;
    padding: 10px;
  }
  .jobLogoContainer:hover {
    padding: 8px;
  }
  .jobConnector {
    margin: -10px 25px;
  }
  .jobCompany {
    font-size: 16px;
    margin-top: 2px;
  }
  .jobRole {
    font-size: 12px;
    margin-bottom: 2px;
  }
}