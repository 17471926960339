.card {
  border: 1px solid black;
  border-radius: 4px;
  box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.35);
  max-width: 300px;
  background: white;
  box-sizing: border-box;
  cursor: pointer;
  transition: transform .4s;
  margin: 20px;
}

.card:hover {
  transition: transform 1s;
  transform: scale(1.05);
}

.details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  margin-top: 15px;
}

.tag {
  padding: 8px 6px;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: bold;
}

.tag[data-color="gold"] {
  background: var(--color-shakers);
}

.tag[data-color="green"] {
  background: var(--color-success);
}

.tag[data-color="orange"] {
  background: var(--color-warning);
}

.tag[data-color="pink"] {
  background: var(--color-code);
  color: white;
}

.tag[data-color="blue"] {
  background: var(--color-info);
  color: white;
}

.calendar {
  width: 20px;
  vertical-align: middle;
  margin-right: 10px;
}

.title {
  padding: 0 15px;
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 40px;
  min-height: 80px;
  display: flex;
  align-items: flex-start;
  text-decoration: none;
  color: var(--color-dark);
}

.title:hover {
  text-decoration: underline;
}

.header {
  text-decoration: none;
}