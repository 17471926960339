.main {
  width: 100%;
  min-width: 298px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(45deg, rgb(39, 88, 32) 0%, rgb(116, 104, 20) 40%, rgb(97, 38, 20) 80%);
}

.rage {
  width: 100%;
  height: 100%;
  position: relative;
}

.rage::before {
  content: "😊";
  position: absolute;
  display: block;
  width: 100%;
  height: 180px;
  line-height: 180px;
  text-align: center;
  font-size: 60px;
  animation: fadeIn 3.5s linear infinite alternate;
}

.rage::after {
  content: "😡";
  position: absolute;
  display: block;
  width: 100%;
  height: 180px;
  line-height: 180px;
  text-align: center;
  font-size: 60px;
  animation: fadeIn 3.5s linear infinite alternate-reverse;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
