.main {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.board {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: 20px 10px;
  width: 90%;
  margin: 30px 20px;
  max-width: 800px;
  flex-shrink: 0;
  transition: opacity .8s;
}

.board[data-status="ended"] {
  opacity: .25;
}

.square {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
}

.card {
  max-width: 100px;
  max-height: 140px;
  border: 1px solid rgba(255, 255, 255, .3);
  background: rgba(255, 255, 255, .02);
  box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.75);
  border-radius: 6px;
  cursor: pointer;
  transition: all .4;
  fill: rgba(0, 0, 0, .7);
  box-sizing: border-box;
  padding: 10px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card svg {
  width: 100%;
}

.card[data-status="selected"] {
  fill: rgba(255, 255, 255, .7);
  background: rgba(255, 255, 255, .1);
}

.controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 40px;
}

.panel {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.title {
  color: white;
  margin: 0;
  font-size: 1.4rem;
  font-weight: bold;
  text-transform: uppercase;
  align-self: start;
}

.timer, .attempts {
  border: 6px solid white;
  border-radius: 50%;
  padding: 15px;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  color: white;
  font-size: 1.6rem;
  font-weight: bold;
  width: 40px;
  height: 40px;
  position: relative;
  margin: 10px 15px;
}

.timer {
  animation: whitePulse 2s infinite alternate;
}

.attempts::before {
  content: " ";
  position: absolute;
  top: 0;
  bottom: 0;
  left: calc(50% - 1px);
  width: 2px;
  background: rgba(255, 255, 255, .2);
}

.attempts::after {
  content: " ";
  position: absolute;
  top: calc(50% - 1px);
  left: 0;
  right: 0;
  height: 2px;
  background: rgba(255, 255, 255, .2);
}

@keyframes whitePulse {
  from {
    background: rgba(255, 255, 255, .05);
  }
  to {
    background: rgba(255, 255, 255, .15);
  }
}

