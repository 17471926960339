.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 298px;
  height: 180px;
  position: relative;
  animation: change 16s infinite;
}

.hook {
  animation: throw 8s infinite alternate linear;
  position: absolute;
  left: 0px;
  width: 40px;
}

@keyframes throw {
  0% {
    transform: rotate(0deg);
    top: 0px;
  }
  33.33% {
    top: 122px;
  }
  66.66% {
    top: 0px;
  }
  100% {
    transform: rotate(1080deg);
    left: calc(100% - 36px);
    top: 122px;
  }
}

@keyframes change {
  0% {
    background: #00d8ff;
  }
  50% {
    background: #0099cc;
  }
  100% {
    background: #00d8ff;
  }
}