.main {
  display: flex;
  flex-direction: column;
  color: white;
  align-items: center;
  justify-content: center;
  max-width: 650px;
  width: 90%;
  margin: 0 auto;
}

.block {
  color: white;
  max-width: 650px;
  font-size: 18px;
  line-height: 28px;
  text-align: justify;
  user-select: none;
}

.block code {
  color: var(--color-code);
}

.block strong {
  color: var(--color-shakers);
}

.buttonArea {
  margin: 30px 0;
}

.moneyButton {
  background-color: transparent;
  color: var(--color-success);
  border: 1px solid var(--color-success);
  border-radius: 50px;
  padding: 16px 42px;
  font-size: 22px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  user-select: none;
}

.moneyButton:hover {
  background-color: rgba(50, 215, 75, .2);
}

.blockchainButton {
  background-color: transparent;
  color: var(--color-shakers);
  border: 1px solid var(--color-shakers);
  border-radius: 50px;
  padding: 16px 42px;
  font-size: 22px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  user-select: none;
}

.blockchainButton:hover {
  background-color: rgba(229, 243, 0, .2);
  
}

.unicornButton {
  background-color: transparent;
  color: var(--color-code);
  border: 1px solid var(--color-code);
  border-radius: 50px;
  padding: 16px 42px;
  font-size: 22px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  user-select: none;
}

.unicornButton:hover {
  background-color: rgba(255, 20, 147, .2);
}

.box {
  background-color: var(--color-dark);
  position: absolute;
  border: 3px solid red;
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.box::before {
  content: "😡";
  display: block;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  font-size: 60px;
}