.main {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 22px;
  align-items: center;
  justify-content: center;
  max-width: 650px;
  width: 90%;
  margin: 0 auto;
}

.message {
  border: 1px solid white;
  border-radius: 4px;
  padding: 20px 20px 50px;
  margin-bottom: 40px;
  color: white;
  font-size: 1.1rem;
  font-weight: 200;
  max-width: 300px;
  min-height: 140px;
  width: calc(100% - 40px);
  box-sizing: border-box;
  padding-bottom: 50px;
  position: relative;
}

.messageLabel {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: white;
  font-size: .6rem;
  font-weight: 400;
  color: var(--color-dark);
  padding: 3px 6px;
  border-radius: 12px;
}

.keyboard {
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;
  width: calc(100% - 40px);
  margin-bottom: 50px;
}

.keyboardExtras {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  max-width: 300px;
  width: calc(100% - 40px);
}

.extraButton {
  flex-basis: calc(100% / 3);
  background-color: transparent;
  border: 1px solid white;
  color: white;
  padding: 12px 12px;
  font-size: 20px;
  cursor: pointer;
}

.button {
  flex-basis: calc(100% / 3);
  background-color: transparent;
  border: 1px solid white;
  color: white;
  padding: 20px 12px;
  font-size: 20px;
  cursor: pointer;
}

.button:hover {
  background-color: white;
  color: black;
}

.button[data-button="1"]:active,
.button[data-button="3"]:active,
.button[data-button="5"]:active,
.button[data-button="7"]:active,
.button[data-button="9"]:active,
.button[data-button="0"]:active {
  background-color: var(--color-shakers);
}

.button[data-button="2"]:active,
.button[data-button="4"]:active,
.button[data-button="6"]:active,
.button[data-button="8"]:active,
.button[data-button="#"]:active,
.button[data-button="*"]:active {
  background-color: var(--color-polygon);
}

.block {
  color: white;
  max-width: 650px;
  font-size: 18px;
  line-height: 28px;
  text-align: justify;
  user-select: none;
}

.block code {
  color: var(--color-code);
}

.block strong {
  color: var(--color-shakers);
}