.cover {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 298px;
  height: 180px;
  background: #07121b;
}

.text {
  animation: neon 1.5s ease-in-out infinite alternate;
  color: white;
  display: inline-block;
  font-family: Monoton;
  font-size: 4em;
  margin: 0;
  max-width: 80%;
  text-decoration: none;
  transition: all 0.5s;
  word-spacing: 25px;
}

@keyframes neon {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #FF1177, 0 0 70px #FF1177, 0 0 80px #FF1177, 0 0 100px #FF1177, 0 0 150px #FF1177;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #FF1177, 0 0 35px #FF1177, 0 0 40px #FF1177, 0 0 50px #FF1177, 0 0 75px #FF1177;
  }
}
