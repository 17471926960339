.card {
  border: 1px dashed black;
  border-radius: 4px;
  box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.05);
  width: 300px;
  background: rgba(255, 255, 255, .01);
  transition: transform .4s;
  margin: 20px;
  box-sizing: border-box;
}

.card:hover {
  transition: transform 1s;
  transform: scale(1.05);
}

.title {
  padding: 0 15px;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 15px;
  margin-bottom: -20px;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, .35);
}

.clock {
  width: 150px;
  height: 150px;
  border: 8px solid rgba(255, 255, 255, .2);
  border-radius: 50%;
  box-shadow: 0 0 1px rgba(255,255,255,0.1) inset;
  position: relative;
  margin: 30px auto 20px;
}

.clock:before,
.clock:after {
  content: "";
  position: absolute;
  bottom: 75px;
  width: 8px;
  background: rgba(255,255,255,0.15);
  border-radius: 4px;
  left: 50%;
  margin: 0 0 0 -4px;
  transform-origin: bottom;
  animation-name: Clock;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  box-shadow: 0 16px 0px rgba(255,255,255,0.15);
}

.clock:before {
  animation-duration: 720s;
  height: 45px;
}
.clock:after {
  animation-duration: 60s;
  height: 65px;
}

@keyframes Clock {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}