.main {
  max-width: 650px;
  width: 90%;
  margin: 0 auto;
}

.block {
  color: white;
  max-width: 650px;
  font-size: 18px;
  line-height: 28px;
  text-align: justify;
}

.block code {
  color: var(--color-code);
}

.link {
  color: var(--color-link);
}

.newLogo {
  margin: 60px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  max-width: 450px;
  animation: shakersColors 10s infinite;
  fill: var(--color-shakers);
}

.buttons {
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.arrowBack,
.arrowForward {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  cursor: pointer;
  transition: transform 1s;   
  background: transparent;
  border: none;
  padding: 0;
  outline: none;
}

.arrowBack:hover .text {
  transform: translateX(-15px);
}

.arrowBack:hover .tail {
  transform: scaleX(1.8);
}

.arrowForward:hover .tail {
  width: 24px;
}

.arrowForward:hover .element {
  right: -6px;
}

.arrowForward .content {
  background-color: var(--color-shakers);
  padding: 12px 20px 12px 14px;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px auto;
}

.arrowBack .content {
  background-color: var(--color-shakers);
  padding: 12px 14px 12px 24px;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px auto;
}

.arrowForward .text {
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
}

.arrowBack .text {
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  transform: translateX(0);
  transition: transform 1s cubic-bezier(0.000, 0.640, 0.310, 1.005)
}

.container {
  position: relative;
  width: 30px;
  height: 16px;
}

.arrowBack .tail {
  position: absolute;
  top: calc(50% - 1px);
  left: 7px;
  width: 13px;
  padding-left: 5px;
  height: 2px;
  background: #000;
  transition: transform .8s cubic-bezier(0.000, 0.640, 0.310, 1.005);
  transform-origin: 100%;
  transform: scaleX(1);
  will-change: transform;
}

.arrowBack .element {
  border: solid black;
  border-width: 0 2px 2px 0;
  position: absolute;
  top: calc(50% - 5px);
  right: 5px;
  display: inline-block;
  padding: 4px;
  transform: rotate(-45deg);
}

.arrowForward .tail {
  position: absolute;
  top: calc(50% - 1px);
  left: 7px;
  width: 13px;
  padding-left: 5px;
  height: 2px;
  background: #000;
  transition: width .8s cubic-bezier(0.000, 0.640, 0.310, 1.005);
  transform-origin: 0%;
  will-change: width;
}

.arrowForward .element {
  border: solid black;
  border-width: 0 2px 2px 0;
  position: absolute;
  top: calc(50% - 5px);
  right: 5px;
  display: inline-block;
  padding: 4px;
  transform: rotate(-45deg);
  transition: right .8s cubic-bezier(0.000, 0.640, 0.310, 1.005);
}

.bubbles {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 30px 0;
}

.bubble {
  width: 135px;
  height: 135px;
  border-radius: 50%;
  border: 1px solid var(--color-shakers);
  box-shadow: 0px 0px 5px 2px rgba(229, 243, 0, 0.40);
  cursor: pointer;
  transition: all .4s;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  font-size: 1.4rem;
}

.bubble:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 5px 4px rgba(229, 243, 0, 0.70);
}

.bubble[data-status="active"] {
  background: var(--color-shakers);
  color: var(--color-shakers-secondary);
}

.bubbleText,
.bubbleEmoji {
  text-transform: capitalize;
  font-weight: bold;
  line-height: 1.95rem;
  margin: 0;
}

@keyframes shakersColors {
  0%, 25% {
    fill: var(--color-shakers);
  }
  33%, 59% {
    fill: var(--color-shakers);
  }
  66%, 95% {
    fill: white;
  }
}