.heart {
  background: url('./resources/heart.png');
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 2900%;
  cursor: pointer;
  display: block;
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.heart[data-status='active'] {
  animation: heart 1s steps(28) forwards;
}

@keyframes heart {
    from {
        background-position: left top;
    }
    to {
        background-position: right top;
    }
}