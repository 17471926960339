.main {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
  padding-top: 60px;
  color: white;
  fill: white;
}

.title {
  text-align: center;
  font-size: 42px;
}

.section {
  margin-bottom: 45px;
}

.subtitle {
  font-size: 30px;
}

.text {
  font-size: 18px;
  letter-spacing: .52px;
  line-height: 24px;
}

.principles {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
}

.principle {
  border: 1px solid white;
  padding: 14px 22px;
  box-sizing: border-box;
  margin: 20px;
  flex: 0 0 45%;
}

.icon {
  width: 50px;
  height: 50px;
  margin: 2px auto 25px;
  display: block;
}

.principleTitle {
  font-weight: 700;
  font-size: 18px;
}

.principleLabel {
  color: #bbb;
  font-size: 14px;
  letter-spacing: .4px;
}

.link {
  color: var(--color-link);
  font-weight: bold;
  cursor: pointer;
}