.tracker {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.container {
  display: flex;
  width: 100%;
  height: calc(100vh - 350px);
  align-items: center;
  justify-content: center;
}

.slowpoke {
  width: 300px;
  max-width: 60%;
  height: 200px;
  max-height: 33%;
}