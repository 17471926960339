.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 30px);
  max-width: 600px;
  margin: 0 auto;
}

.score {
  border: 1px solid white;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.scoreInfo {
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}

.scoreData {
  color: var(--color-shakers);
}

.title {
  font-size: 2.5em;
  color: white;
}

.feedback[data-status='correct'] {
  color: var(--color-success);
}

.feedback[data-status='incorrect'] {
  color: var(--color-error);
}

.country {
  color: var(--color-code);
}

.flags {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.button {
  flex-basis: calc(50% - 50px);
  background-color: transparent;
  border: 1px solid var(--color-code);
  font-size: 8rem;
  cursor: pointer;
  margin: 25px;
  transition: all .4s;
}

.button:hover {
  border-color: var(--color-shakers);
  background-color: rgba(229, 243, 0, 0.1);
}

.again {
  cursor: pointer;
  text-decoration: underline;
}

.again:hover {
  color: var(--color-link);
}

.result {
  color: white;
  width: 100%;
  font-size: 2em;
}

.resultScore {
  width: 100%;
  font-size: 4rem;
  margin: 0;
}

.resultFlag {
  margin: 12px;
}

@media(max-width: 450px) {
  .button {
    font-size: 6rem;
    margin: 20px;
    flex-basis: calc(50% - 40px);
  }
  .score {
    flex-direction: column;
    align-items: flex-start;
  }
  .scoreInfo {
    margin: 12px 0;
  }
  .title {
    font-size: 2.2rem;
  }
}

@media(max-width: 400px) {
  .button {
    font-size: 5rem;
    margin: 15px;
    flex-basis: calc(50% - 30px);
  }
}

@media(max-width: 350px) {
  .button {
    font-size: 4rem;
    margin: 12px;
    flex-basis: calc(50% - 24px);
  }
}