.main {
  max-width: 650px;
  width: 90%;
  margin: 0 auto;
}

.block {
  color: white;
  max-width: 650px;
  font-size: 18px;
  line-height: 28px;
  text-align: justify;
}

.calculator {
  display: flex;
  max-width: 860px;
  width: 90%;
  margin: 20px auto 0;
}

.id {
  flex-grow: 1;
  box-sizing: border-box;
}

.date {
  flex-grow: 1;
  box-sizing: border-box;
}

.input {
  margin: 12px 0;
}

.input > label {
  font-weight: bold;
  color: #b1ffb1;
  text-transform: uppercase;
  font-size: 14px;
  display: block;
}

.input > input {
  background: transparent;
  border: 2px solid #b1ffb1;
  margin: 10px;
  color: #b1ffb1;
  padding: 12px 20px;
  border-radius: 3px;
  font-size: 18px;
  max-width: 380px;
  width: calc(100% - 20px);
  box-sizing: border-box;
}

.input > .readonly {
  color: #50ad50;
}

.input > input:read-only {
  color: #50ad50;
  border-color: #50ad50;
  outline: none;
}

.input > input:invalid:not(:focus) {
  border-color: #f58282;
  color: #f58282;
}

@media(max-width: 895px) {
  .calculator {
    flex-direction: column;
    align-items: center;
  }
  .input > input {
    width: 380px;
    max-width: calc(100% - 20px);
  }
}