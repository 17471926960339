
.main {
  border: 1px solid white;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.total {
  font-size: 1.5em;
  color: white;
  text-align: center;
  user-select: none;
}

.button {
  background-color: transparent;
  border: none;
}

.icon {
  display: block;
  fill: white;
  width: 160px;
  height: 160px;
}