@import url('https://fonts.googleapis.com/css2?family=Nosifer&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap');


.cover {
  width: 100%;
  min-width: 298px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  background:  rgb(254, 255, 156);
  font-size: 2rem;
  letter-spacing: 3px;
  color: #bb0a1e;
  font-family: 'Reenie Beanie', cursive;
}

.message {
  animation-name: creepyfont;
  animation-duration: 5s;
  animation-direction: alternate;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

@keyframes creepyfont {
  0% {
    font-family: 'Reenie Beanie', cursive;
    font-size: 3.5rem;
    letter-spacing: 6px;
    opacity: 1;
  }
  49% {
    font-family: 'Reenie Beanie', cursive;
    font-size: 3.5rem;
    letter-spacing: 6px;
    opacity: 0;
  }
  51% {
    font-family: 'Nosifer', cursive;
    font-size: 2rem;
    letter-spacing: 3px;
    opacity: 0;
  }
  100% {
    font-family: 'Nosifer', cursive;
    font-size: 2rem;
    letter-spacing: 3px;
    opacity: 1;
  }
}
