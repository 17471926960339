.main {
  height: 100vh;
  width: 100vw;
  cursor: none;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 50px;
  box-sizing: border-box;
}

.monkey {
  position: absolute;
  font-size: 40px;
  line-height: 40px;
  transform: translate(-20px, -20px);
  top: 50%;
  left: 50%;
}

.light {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  cursor: none;
  pointer-events: none;
  background: radial-gradient(circle at 50% 50%, transparent, #000000 80px);
}

.back {
  background-color: transparent;
  color: white;
  border: 1px solid white;
  border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
  padding: 8px 12px;
}

.back:hover {
  background-color: rgba(255, 255, 255, .2);
}

.content {
  max-width: 650px;
  margin: 0 auto;
  width: calc(100% - 40px);
}

.block {
  color: white;
  max-width: 650px;
  font-size: 18px;
  line-height: 28px;
  text-align: justify;
}

.block a {
  color: var(--color-link);
  font-weight: bold;
}

.block code {
  color: var(--color-code);
}

.block strong {
  color: var(--color-shakers);
}

.block .play {
  color: var(--color-shakers);
  font-weight: bold;
  cursor: pointer;
}