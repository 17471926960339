.main {
  position: relative;
  min-width: 298px;
  width: 100%;
  height: 180px;
  background-color: #f99;
}

.sound {
  width: 100%;
  height: 100%;
  padding: 30px 10px;
  box-sizing: border-box;
}

.wave {
  animation: sound 1s alternate infinite;
  transform-origin: 50% 50%;
}

.wave[data-line="1"] {
  animation-delay: .15s;
}

.wave[data-line="2"] {
  animation-delay: .3s;
}

.wave[data-line="3"] {
  animation-delay: .45s;
}

.wave[data-line="4"] {
  animation-delay: .6s;
}

.wave[data-line="5"] {
  animation-delay: .75s;
}

.wave[data-line="6"] {
  animation-delay: .9s;
}

.wave[data-line="7"] {
  animation-delay: 1.05s;
}

.wave[data-line="8"] {
  animation-delay: 1.2s;
}

.wave[data-line="9"] {
  animation-delay: 1.35s;
}

@keyframes sound {
  from { transform: scaleY(1); }
  to { transform: scaleY(0.7); }
}