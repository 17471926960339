
.credit {
  height: 25px;
  background: #1da1f2;
  display: inline-block;
  border-radius: 4px;
  text-decoration: none;
}

.content {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-around;
  padding: 2px 8px;
  box-sizing: border-box;
}

.logo {
  height: 100%;
  margin-right: 10px;
}

.handle {
  font-size: .8rem;
  color: white;
  text-decoration: none;
}