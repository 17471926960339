.main {
  width: 100%;
  min-width: 298px;
  height: 180px;
  padding: 65px 0;
  box-sizing: border-box;
  background: papayawhip;
}

.scissors {
  position: relative;
  width: 210px;
  height: 63px;
  margin: 0px auto;
}

.half {
  width: 210px;
  height: 35px;
  position: absolute;
  top: 0;
  left: 0;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  transform-origin: 42.33333% 100% 0;
  transform: rotate(10deg);
  animation: rot-up 1s ease-out infinite;
}

.half:nth-child(even) {
  top: 28px;
  transform-origin: 42.33333% 0% 0;
  transform: rotate(-10deg);
  animation: rot-down 1s ease-out infinite;
}

.half:nth-child(even) .blade {
  top: 0;
  border-radius: 0 7px 0 0;
}

.half:nth-child(even) .blade:after {
  top: 7px;
  border-style: solid;
  border-width: 7px 14px 0 14px;
  border-color: var(--color-warning) transparent transparent transparent;
}

.joint {
  width: 4.9px;
  height: 4.9px;
  border-radius: 50%;
  background: #353535;
  position: absolute;
  top: 29.05px;
  left: 41.16667%;
}

.handle {
  width: 70px;
  height: 35px;
  border-radius: 17.5px;
  border: 7px solid #353535;
  background: #bbbbbb;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
}

.blade {
  width: 140px;
  height: 7px;
  position: absolute;
  top: 28px;
  left: 70px;
  background: #e19200;
  border-radius: 0 0 7px 0;
}

.blade:before {
  content: '';
  width: 35px;
  height: 7px;
  background: #e19200;
  position: absolute;
  top: 0;
  left: -35px;
}

.blade:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 14px 7px 14px;
  border-color: transparent transparent #e19200 transparent;
  position: absolute;
  top: -7px;
  left: -14px;
}

@keyframes rot-up {
  0% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(40deg);
  }
  100% {
    transform: rotate(10deg);
  }
}
@keyframes rot-down {
  0% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(-40deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}