.main {
  max-width: 650px;
  width: 90%;
  margin: 0 auto;
}

.block {
  color: white;
  max-width: 650px;
  font-size: 18px;
  line-height: 28px;
  text-align: justify;
}