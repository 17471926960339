.main {
  height: 180px;
  width: 100%;
  min-width: 298px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, #163C52 0%,#4F4F47 30%,#C5752D 60%,#B7490F 80%, #2F1107 100%);
}

.slowpoke {
  max-height: 160px;
  max-width: 240px;
}