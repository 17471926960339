.main {
  margin: 60px auto 50px auto;
  display: flex;
  justify-content: center;
  max-width: 90%;
  text-align: center;
}

.link {
  border: 1px solid white;
  border-radius: 4px;
  background: transparent;
  color: white;
  padding: 16px 48px;
  font-size: 32px;
  text-transform: uppercase;
  display: inline-block;
  cursor: pointer;
  transition: all 1s;
  box-sizing: border-box;
  text-decoration: none;
  user-select: none;
}

.link:hover {
  background: rgba(255, 255, 255, .15);
}

.link::before {
  content: '← ';
  width: 0px;
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
  transition: width 1s;
}

.link:hover::before {
  width: 40px;
}