html {
  background-color: var(--color-dark);
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

:root {
  --color-dark: #282c34;

  --color-success: #32d74b;
  --color-success-dark: #146a21;
  --color-warning: #ff9f0a;
  --color-error: #f56157;
  --color-info: #00bfff;

  --color-code: deeppink;
  --color-link: #00ccaa;

  --color-polygon: #8247e5;
  --color-shakers: #e5f300;
  --color-shakers-secondary: #2a016c;
}