.cover {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 298px;
  width: 100%;
  height: 180px;
  background: url('./resources/gecko.jpeg');
  background-size: cover;
  animation: grayify 4s infinite alternate;
}

@keyframes grayify {
  from {
    filter: grayscale(0);
  }
  to {
    filter: grayscale(1);
  }
}