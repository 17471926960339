.cover {
  width: 100%;
  min-width: 298px;
  height: 180px;
  padding: 65px 0;
  box-sizing: border-box;
  background: #0F3325;
  display: flex;
  align-items: center;
  justify-content: center;
}


.nftIcon {
  height: 80px;
  width: 80px;
}

.nftIcon[data-index="1"] {
  animation: loop-forward 6s linear infinite;
}

.nftIcon[data-index="2"] {
  animation: loop-backward 6s linear infinite;
}

@keyframes loop-forward {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    transform: translateX(0);
  }
  40% {
    opacity: 0;
    transform: translateX(-80px);
  }
  60% {
    opacity: 0;
    transform: translateX(80px);
  }
  80% {
    opacity: 1;
    transform: translateX(80px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes loop-backward {
  0% {
    opacity: 0;
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    transform: translateX(0);
  }
  40% {
    opacity: 1;
    transform: translateX(-80px);
  }
  60% {
    opacity: 1;
    transform: translateX(-80px);
  }
  80% {
    opacity: 1;
    transform: translateX(-80px);
  }
  100% {
    opacity: 0;
    transform: translateX(-160px);
  }
}