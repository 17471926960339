.main {
  min-width: 298px;
  width: 100%;
  height: 180px;
  background-color: #444;
  position: relative;
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
}

.text {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  line-height: 180px;
  margin: 0;
  font-size: 72px;
  font-weight: bold;
  color: transparent;
  animation: cool-background 15s ease infinite;
  background: linear-gradient(to right, #EE9782, #E75CAE, #73B6DF, #63D9B4);
  background-size: 400% 400%;
  -webkit-background-clip: text;
  background-clip: text;
}

@keyframes cool-background {
  0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}