.area {
  padding: 20px;
  background: #222233;
  display: block;
  overflow-x: scroll;
  max-width: 800px;
  color: white;
  margin: 40px 0;
  border: 1px solid #444466;
}

.line {
  white-space: nowrap;
}

.line[data-weight='strong'] {
  font-weight: bold;
}

.line[data-style='italic'] {
  font-style: italic;
}

.fragment[data-modifier="strong"] { font-weight: bold; }
.fragment[data-modifier="italic"] { font-style: italic; }

.fragment[data-color="blue"] { color: cyan; }
.fragment[data-color="blue-2"] { color: lightskyblue; }

.fragment[data-color="violet"] { color: darkorchid; }
.fragment[data-color="violet-2"] { color: violet; }
.fragment[data-color="violet-3"] { color: fuchsia; }

.fragment[data-color="pink"] { color: deeppink; }

.fragment[data-color="red"] { color: crimson; }

.fragment[data-color="orange"] { color: orangered; }
.fragment[data-color="orange-2"] { color: tomato; }
.fragment[data-color="orange-3"] { color: orange; }

.fragment[data-color="yellow"] { color: gold; }

.fragment[data-color="wood"] { color: burlywood; }

.fragment[data-color="green"] { color: lime; }
.fragment[data-color="green-2"] { color: #17a315; }

.fragment[data-color="grey"] { color: dimgray; }
.fragment[data-color="grey-2"] { color: #999999; }
.fragment[data-color="grey-3"] { color: #bbbbbb; }
.fragment[data-color="grey-4"] { color: #eeeeee; }

