.cover {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
  width: 100%;
  min-width: 298px;
  background: radial-gradient(circle, rgba(63,214,251,1) 0%, rgba(10,14,111,1) 100%);
}

.monitor {
  position: absolute;
}

.screen {
  border: 4px solid #474e5d;
  border-top-width: 6px;
  border-radius: 10px;
  width: 115px;
  height: 70px;
  background-image: linear-gradient(135deg, #282c34 0%, #282c34 90%, #1e2124 100%);
}

.screen:before {
  content: "";
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: rgba(255, 255, 255, .4);
  display: block;
  position: absolute;
  left: calc(50% - 1.5px);
  top: 1px;
}

.base {
  width: 14px;
  height: 20px;
  background: linear-gradient(180deg, #373e4d 0%, #373e4d 25%, #474e5d 30%, #474e5d 100%);
  position: absolute;
  left: calc(50% - 7px);
}

.base:before {
  content: "";
  position: absolute;
  top: 0;
  border-left: 6px solid transparent;
  border-right: 0px solid transparent;
  border-bottom: 20px solid #474e5d;
  left: -6px;
}

.base:after {
  content: "";
  position: absolute;
  top: 0;
  border-right: 6px solid transparent;
  border-left: 0px solid transparent;
  border-bottom: 20px solid #474e5d;
  right: -6px;
}

.foot {
  width: 50px;
  height: 4px;
  background: #474e5d;
  position: absolute;
  top: 100px;
  left: calc(50% - 25px);
  z-index: 2;
}

.shadow {
  width: 100px;
  height: 15px;
  position: absolute;
  left: calc(50% - 50px);
  top: 98px;
  z-index: 1;
  background: radial-gradient(ellipse at center, rgba(0,0,0,.2) 0%,rgba(0,0,0,.2) 53%,rgba(0,0,0,0) 55%,rgba(0,0,0,0) 100%);
}

.icon {
  width: 40px;
  height: 40px;
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  fill: #1da1f2;
  animation: twitterShake .15s infinite alternate linear;
}

@keyframes twitterShake {
  from {
    transform: rotate(-3deg);
  }
  to {
    transform: rotate(3deg);
  }
}