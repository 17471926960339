.main {
  max-width: 650px;
  margin: 0 auto;
  width: calc(100% - 40px);
}

.header {
  color: white;
  max-width: 650px;
  font-size: 25px;
  line-height: 32px;
  letter-spacing: .3px;
  text-align: justify;
  margin-top: 45px;
}

.block {
  color: white;
  max-width: 650px;
  font-size: 18px;
  line-height: 35px;
  text-align: justify;
  margin-bottom: 30px;
}

.block a {
  color: var(--color-link);
  font-weight: bold;
}

.block code {
  color: var(--color-code);
}

.block strong {
  color: var(--color-shakers);
}

.icons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 0;
}

.icon {
  fill: white;
  width: 40px;
  height: 40px;
  margin: 10px 20px;
}

.icon:hover {
  fill: var(--color-code);
}

.dots {
  margin: 80px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgba(255, 255, 255, .2);
  margin: 0 10px;
}