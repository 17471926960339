.main {
  width: 100%;
  min-width: 298px;
  height: 180px;
  background-color: #cacaca;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box {
  width: 210px;
  height: 50px;
  background-color: indianred;
  box-shadow: 5px 2px 0px 0px rgba(0, 0, 0, 0.7);
  animation: shadowGenerator 8s infinite linear;
}

.code {
  line-height: 50px;
  text-align: center;
  width: 100%;
  display: block;
  color: white;
  font-weight: bold;
}

@keyframes shadowGenerator {
  0% { 
    box-shadow: 12px -12px 0px 0px rgba(0, 0, 0, 0.7);
  }
  25% { 
    box-shadow: 12px 12px 0px 0px rgba(0, 0, 0, 0.7);
  }
  50% { 
    box-shadow: -12px 12px 0px 0px rgba(0, 0, 0, 0.7);
  }
  75% { 
    box-shadow: -12px -12px 0px 0px rgba(0, 0, 0, 0.7);
  }
  100% { 
    box-shadow: 12px -12px 0px 0px rgba(0, 0, 0, 0.7);
  }
}