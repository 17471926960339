.cover {
  width: 100%;
  min-width: 298px;
  height: 180px;
  box-sizing: border-box;
  background: #121212;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar {
  width: 100%;
  height: 100px;
  fill: #aa1b1b;
  animation: darthVader 5s infinite;
}

@keyframes darthVader {
  0% {
    fill: #5a1212;
  }
  40% {
    fill: red;
  }
  60% {
    fill: red;
  }
  100% {
    fill: #5a1212;
  }
}