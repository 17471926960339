.main {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  text-align: center;
  padding: 20px 10px;
  box-sizing: border-box;
}

.level {
  color: white;
  text-transform: uppercase;
  font-size: 3.5rem;
  margin: 20px 0;
  font-weight: 600;
}

.depth {
  color: var(--color-error);
  text-transform: uppercase;
  word-break: break-word;
  font-size: 2rem;
}

.url {
  color: white;
  text-transform: uppercase;
  font-size: 1.8rem;
}

.arrows {
  color: white;
  text-transform: uppercase;
  font-size: 4rem;
  margin: 25px 0;
}

.links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.link {
  display: inline-block;
  color: white;
  border: 1px solid white;
  border-radius: 5px;
  padding: 25px 40px;
  margin: 20px 0;
  text-decoration: none;
  transition: all .6s;
  font-size: 1.5rem;
  font-weight: 600;
}

.link:hover {
  background-color: rgba(255, 255, 255, 1);
  color: var(--color-dark);
}