.main {
  width: calc(100% - 20px);
  max-width: 600px;
  margin: 30px auto 0;
}

.message {
  font-size: 28px;
  text-transform: uppercase;
  text-align: center;
  margin: 20px;
}

.hint {
  font-weight: 200;
  color: #aaa;
}

.successMessage {
  color: var(--color-success);
}

.history {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.history::after {
  bottom: 0;
  position: absolute;
  content: "";
  display: block;
  border: 1px solid white;
  width: 100%;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.attempt {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.pieceStack {
  width: 400px;
  max-width: calc(100% - 40px);
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  padding: 10px 40px;
  box-sizing: border-box;
  border: 1px solid white;
}

.evaluation {
  width: 80px;
  margin-left: 50px;
  padding: 10px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  align-self: stretch;
}

.buttonContainer {
  width: 80px;
  height: 80px;
  margin-left: 50px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  background: transparent;
  border: none;
  color: white;
  padding: 12px;
  border-radius: 50%;
  border: 1px solid white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background: rgba(255, 255, 255, 0.4);
}

.button:disabled {
  opacity: .4;
  cursor: not-allowed;
}

.icon {
  width: 30px;
  height: 30px;
  fill: white;
}

.evaluationPiece {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #fff;
  box-sizing: border-box;
  margin: 5px;
}

.evaluationPiece[data-status="correct"] {
  background-color: var(--color-success);
}

.evaluationPiece[data-status="misplaced"] {
  background-color: var(--color-shakers);
}

.piece {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 3px solid #fff;
  box-sizing: border-box;
  margin: 15px;
}

.piece[data-drag-status='dragging'][data-action='interactive'] {
  box-shadow: 0px 0px 15px 3px rgba(255,255,255,0.3);
}

.piece[draggable=true][data-action='interactive'] {
  cursor: grab;
}

.piece[data-action='interactive'] {
  cursor: pointer;
}

.piece[data-color="orange"] {
  background-color: var(--color-warning);
}

.piece[data-color="red"] {
  background-color: var(--color-error);
}

.piece[data-color="blue"] {
  background-color: var(--color-info);
}

.piece[data-color="pink"] {
  background-color: var(--color-code);
}

.piece[data-color="purple"] {
  background-color: var(--color-shakers-secondary);
}

.piece[data-color="green"] {
  background-color: var(--color-link);
}

@media(max-width: 600px) {
  .piece {
    width: 40px;
    height: 40px;
    margin: 8px;
  }
  .evaluationPiece {
    width: 12px;
    height: 12px;
    margin: 3px;
  }
  .icon {
    width: 20px;
    height: 20px;
  }
  .pieceStack {
    width: 300px;
  }
  .message {
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
    margin: 20px;
  }
  .evaluation {
    width: 50px;
    margin-left: 30px;
    padding: 12px 6px;
  }
  
  .buttonContainer {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    margin-right: 6px;
  }
}