.main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 298px;
  height: 180px;
  background: #245a29
}

.icon {
  animation: clockbounce 1s linear infinite, clockcolor 10s linear infinite 0.5s;
  width: 90px;
  height: 90px;
}

@keyframes clockbounce {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-20px);
  }
}

@keyframes clockcolor {
  0% {
    fill: white;
  }
  19% {
    fill: white;
  }
  20% {
    fill: turquoise;
  }
  39% {
    fill: turquoise;
  }
  40% {
    fill: salmon;
  }
  59% {
    fill: salmon;
  }
  60% {
    fill: deeppink;
  }
  79% {
    fill: deeppink;
  }
  80% {
    fill: goldenrod;
  }
  99% {
    fill: goldenrod;
  }
  100% {
    fill: white;
  }
}