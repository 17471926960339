.main {
  max-width: 650px;
  margin: 0 auto;
  width: calc(100% - 40px);
}

.header {
  color: white;
  max-width: 650px;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: .3px;
  text-align: justify;
  margin-top: 85px;
}

.block {
  color: white;
  max-width: 650px;
  font-size: 18px;
  line-height: 28px;
  text-align: justify;
}

.imagesBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.images {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.characters {
  text-align: center;
  color: #f36761;
  font-size: 28px;
  line-height: 40px;
  font-family: 'Courier New', Courier, monospace;
  letter-spacing: 5px;
}

.image {
  display: block;
  max-width: 90%;
  margin: 30px auto;
}

.result {
  font-family: 'Courier New', 'Courier', monospace;
  font-size: 6px;
  line-height: 6px;
  letter-spacing: -0.7px;
  color: white;
  display: inline-block;
  transform: translateX(calc(-50% + 325px));
  margin-top: 45px;
}

.result {
  display: block;
}

.filter {
  border: 1px solid white;
  background: linear-gradient(to right, rgb(230, 39, 6) 0%, rgb(20, 128, 23) 50%, rgb(4, 37, 119) 100%);
  padding: 16px 32px;
  border-radius: 36px;
  color: white;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 1.2px;
  cursor: pointer;
  outline: none;
  box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.35);
}

@media(max-width: 950px) {
  .result {
    font-size: 4px;
    line-height: 4px;
    letter-spacing: -0.4px;
  }
}

@media(max-width: 695px) {
  .result {
    text-align: center;
    transform: none;
    display: block;
  }
}

@media(max-width: 660px) {
  .result {
    font-size: 3px;
    line-height: 3px;
    letter-spacing: -0.3px;
  }
  .images {
    flex-direction: column;
    align-items: center;
  }
  .images img:first-of-type {
    margin-bottom: 25px;
  }
}

@media(max-width: 520px) {
  .result {
    font-size: 2px;
    line-height: 2px;
    letter-spacing: -0.2px;
  }
}