.main {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}

.rulesList {
  color: white;
  max-width: 650px;
  font-size: 18px;
  line-height: 28px;
  text-align: justify;
}

.game {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
  max-width: calc(100% - 40px);
}

.system {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 25px;
  margin-bottom: 50px;
}


.player {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 50px;
}

.systemTitle {
  flex-basis: 100%;
  color: var(--color-warning);
  text-align: center;
  margin: 0 0 10px;
}

.playerTitle {
  flex-basis: 100%;
  color: var(--color-info);
  text-align: center;
  margin: 0 0 10px;
}

@media(max-width: 980px) {
  .main {
    flex-direction: column;
    align-items: center;
  }
  .rules {
    margin: 15px 20px 0;
  }
  .game {
    margin: 25px 20px 0;
  }
}

@media(max-width: 480px) {
  .game {
    padding: 10px;
  }
  .button {
    font-size: 16px;
  }
}