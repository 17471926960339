.main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: calc(100% - 40px);
  margin: 20px auto;
}

.intro {
  color: white;
  text-align: center;
}

.progressText {
  font-size: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 60px;
}

.errorText,
.successText {
  font-size: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  min-height: 60px;
}

.errorTitle {
  color: var(--color-error);
}

.successTitle {
  color: var(--color-success);
}

.progressQueens {
  margin-left: 20px;
  font-size: 40px;
}