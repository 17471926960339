.card {
  border: 1px solid black;
  border-radius: 4px;
  box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.35);
  max-width: 300px;
  background: white;
  box-sizing: border-box;
  cursor: pointer;
  transition: transform .4s;
  margin: 20px;
  min-height: 378px;
}

.card:hover {
  transition: transform 1s;
  transform: scale(1.05);
}

.title {
  padding: 0 15px;
  font-size: 20px;
  margin-top: 25px;
  margin-bottom: 20px;
  min-height: 40px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--color-dark);
}

.title:hover {
  text-decoration: underline;
}

.description {
  padding: 0 15px;
  color: #323232;
  font-style: italic;
  margin-bottom: 45px;
}

.header {
  text-decoration: none;
}