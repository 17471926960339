.main {
  max-width: 650px;
  margin: 0 auto;
  width: calc(100% - 40px);
}

.header {
  color: white;
  max-width: 650px;
  font-size: 25px;
  line-height: 32px;
  letter-spacing: .3px;
  text-align: justify;
  margin-top: 45px;
}

.block {
  color: white;
  max-width: 650px;
  font-size: 18px;
  line-height: 35px;
  text-align: justify;
  margin-bottom: 30px;
}

.block a {
  color: var(--color-link);
  font-weight: bold;
}

.block code {
  color: var(--color-code);
}

.block strong {
  color: var(--color-shakers);
}

.blockchain {
  height: 80px;
  margin: 0 auto;
  display: block;
  animation: rotate 4s linear infinite;
}

.icons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
}

.nftIcon {
  width: 120px;
  height: 80px;
  display: block;
}

.polygon {
  width: 120px;
  height: 80px;
  margin: 0 auto;
  display: block;
}

.polygon path {
  stroke: var(--color-polygon);
  animation: fill 4s linear infinite alternate;
}

.nft {
  width: 300px;
  height: 300px;
  margin: 0 auto;
  display: block;
}

.button {
  background-color: transparent;
  cursor: pointer;
  color: var(--color-polygon);
  font-weight: bold;
  text-decoration: underline;
  border: none;
  font-size: 18px;
  line-height: 35px;
  padding: 0;
}

.button:hover {
  color: #9b6bea;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fill {
  from {
    fill: var(--color-polygon)
  }
  to {
    fill: transparent
  }
}