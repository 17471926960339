.content {
  margin: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.grid {
  --grid-size: repeat(10, 50px);
  display: grid;
  grid-template-rows: var(--grid-size);
  grid-template-columns: var(--grid-size);
  touch-action: none;
}

.cell {
  outline: 1px solid #efefef;
}

.block {
  color: white;
  max-width: 650px;
  font-size: 18px;
  line-height: 28px;
  text-align: justify;
  margin: 30px auto 10px;
}

@media(max-width: 600px) {
  .grid {
    --grid-size: repeat(10, 30px);
  }
}

@media(max-width: 400px) {
  .grid {
    --grid-size: repeat(10, 24px);
  }
}