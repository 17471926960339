.icon {
  width: 25px;
  height: 25px;
  flex-shrink: 0;
  display: block;
  fill: white;
  transition: all .5s;
}

.content {
  transition: all .5s;
}

.content[data-icon='twitter']:hover {
  fill: #00acee;
}

.content[data-icon='instagram']:hover {
  fill: #e95950;
}

.content[data-icon='linkedin']:hover {
  fill: #0e76a8;
}

.content[data-icon='github']:hover {
  fill: #6f42c1;
}

.content[data-icon='mail']:hover {
  fill: #03c04a;
}

.content[data-icon='polywork']:hover {
  fill: #F2C94C;
}