.main {
  max-width: 650px;
  margin: 0 auto;
  width: calc(100% - 40px);
}

.block {
  color: white;
  max-width: 650px;
  font-size: 18px;
  line-height: 28px;
  text-align: justify;
}

.collapsible {
  display: flex;
  align-items: center;
  max-width: 300px;
  margin: 25px auto;
  justify-content: space-around;
}

.face {
  font-size: 60px;
}

.button {
  background: transparent;
  color: white;
  border: 1px solid white;
  border-radius: 5px;
  padding: 16px 32px;
  font-size: 20px;
  cursor: pointer;
}