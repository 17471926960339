.chessBoard {
  display: flex;
  flex-direction: column;
  --size: 9vmin;
  margin: 40px auto;
}

.chessRow {
  margin: 0;
  font-size: 0;
  display: flex;
  flex-direction: row;
}

.chessCell {
  width: calc(1 * var(--size));
  height: calc(1 * var(--size));
  font-size: calc(7 / 5 * var(--size));
  line-height: calc(21 / 25 * var(--size));
  display: flex;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  position: relative;
}

.chessCell[data-color="black"] {
  background-color: #d08b18;
}

.chessCell[data-color="white"] {
  background-color: #fce4b4;
}

.chessCell[data-visited="true"]::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-success);
  opacity: .5;
}

.piece {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}