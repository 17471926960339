.icon {
  width: 30px;
  height: 30px;
}

.icon > svg {
  width: 100%;
  height: 100%;
}

.icon:hover {
  transform: scale(1.1);
}

@media(max-width: 600px) {
  .icon {
    width: 20px;
    height: 20px;
  }
}