.main {
  max-width: 650px;
  margin: 0 auto;
  width: calc(100% - 40px);
}

.block {
  color: white;
  max-width: 650px;
  font-size: 18px;
  line-height: 28px;
  text-align: justify;
}

.block a {
  color: var(--color-link);
}

.block strong {
  color: var(--color-shakers);
}