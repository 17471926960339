.main {
  width: 100%;
  min-width: 298px;
  height: 180px;
  background-color: #862d86;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  font-family: 'Courier New', Courier, monospace;
  color: white;
  font-size: 48px;
  font-weight: bold;
  display: flex;
  align-items: center;
  line-height: 60px;
  height: 60px;
}

.dot {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: white;
  border-radius: 50%;
  margin: 15px;
  animation: destructurefade infinite 2s ease-in;
}

.dot:nth-of-type(2) {
  animation-delay: .5s;
}

.dot:nth-of-type(3) {
  animation-delay: 1s;
}

@keyframes destructurefade {
  0% {
    opacity: 1;
  }
  20% {
    opacity: .1;
  }
  80% {
    opacity: .1;
  }
  100% {
    opacity: 1;
  }
}