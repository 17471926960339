.cover {
  width: 100%;
  min-width: 298px;
  height: 180px;
  padding: 65px 0;
  box-sizing: border-box;
  background: #1868B7;
  display: flex;
  align-items: center;
  justify-content: center;
}


.nftIcon {
  height: 80px;
  width: 80px;
  animation: spin 6s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(1.6);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}