.main {
  margin-top: 15px;
}

.information {
  letter-spacing: 6px;
  margin: 5px 0;
}

.detail {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: .1px;
  font-size: 18px;
  color: var(--color-code);
}

.result {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: .1px;
  font-size: 32px;
  color: white;
  text-align: center;
  margin: 15px 0 35px;
}

@media(max-width: 500px) {
  .result {
    font-size: 30px;
  }
}