.sword {
  height: 75vh;
}

.sword[data-sword='vader'] {
  background: radial-gradient(circle, rgba(212,7,7,.1) 0%, rgba(212,7,7,.05) 15%, rgba(212,7,7,.01) 25%, rgba(212,7,7,.0) 30%);
}

.sword[data-sword='yoda'] {
  background: radial-gradient(circle, rgba(7,212,7,.1) 0%, rgba(7,212,7,.05) 15%, rgba(7,212,7,.01) 25%, rgba(7,212,7,.0) 30%);
}

.sword[data-sword='luke'] {
  background: radial-gradient(circle, rgba(63,94,251,.1) 0%, rgba(63,94,251,.05) 15%, rgba(63,94,251,.01) 25%, rgba(63,94,251,.0) 30%);
}

.sword[data-sword='windu'] {
  background: radial-gradient(circle, rgba(212,7,212,.1) 0%, rgba(212,7,212,.05) 15%, rgba(212,7,212,.01) 25%, rgba(212,7,212,.0) 30%);
}

.sword[data-sword='rey'] {
  background: radial-gradient(circle, rgba(212,212,7,.1) 0%, rgba(212,212,7,.05) 15%, rgba(212,212,7,.01) 25%, rgba(212,212,7,.0) 30%);
}

.sword[data-status='off'] {
  background: transparent;
}

.element {
  width: 100%;
  height: 100%;
  transition: all 2s;
}

.button {
  cursor: pointer;
}

.avatars {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 0 20px;
  flex-wrap: wrap;
}

.avatar {
  width: 60px;
  margin: 10px 20px;
  cursor: pointer;
  transition: all .6s;
}

.avatar[data-status='active'][data-avatar='luke'] {
  fill: rgb(63,94,251);
}

.avatar[data-status='active'][data-avatar='vader'] {
  fill: rgb(212,7,7);
}

.avatar[data-status='active'][data-avatar='yoda'] {
  fill: rgb(7,212,7);
}

.avatar[data-status='active'][data-avatar='windu'] {
  fill: rgb(212, 7, 212);
}

.avatar[data-status='active'][data-avatar='rey'] {
  fill: rgb(212,212,7);
}


.avatar[data-avatar='luke']:hover {
  fill: rgb(63,94,251);
}

.avatar[data-avatar='vader']:hover {
  fill: rgb(212,7,7);
}

.avatar[data-avatar='yoda']:hover {
  fill: rgb(7,212,7);
}

.avatar[data-avatar='windu']:hover {
  fill: rgb(212, 7, 212);
}

.avatar[data-avatar='rey']:hover {
  fill: rgb(212,212,7);
}
