.cover {
  width: 100%;
  min-width: 298px;
  height: 180px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.shadow {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  background-image: radial-gradient(
    circle at 50% 50%, 
    transparent, 
    #000000 80px
  );
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.monkey {
  font-size: 40px;
}

.monkey::before {
  content: "";
  animation: closure 4s ease-in-out infinite;
}

@keyframes closure {
  0% {
    content: "🐵";
  }
  100% {
    content: "🙈";
  }
}
