.main {
  margin-top: 80px;
}

.title {
  color: white;
  text-align: center;
  font-weight: 400;
}

.intro {
  width: calc(100% - 80px);
  max-width: 1200px;
  margin: 20px auto;
  color: white;
  line-height: 1.7rem;
}

.tabInput {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.tabs {
  overflow: hidden;
  width: calc(100% - 80px);
  max-width: 1200px;
  margin: 20px auto;
}

.tab {
  width: 100%;
  color: white;
  overflow: hidden;
}

.tabLabel {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  font-weight: bold;
  cursor: pointer;
  font-size: 1.2rem;
  border-bottom: 1px solid white;
}

.tabLabel:hover {
  color: var(--color-shakers);
  border-bottom-color: var(--color-shakers);
}

.tabLabel::after {
  content: "❯";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
  transform-origin: center;
  transform: rotate(90deg);
}

.tabContent {
  max-height: 0;
  padding: 0 1em;
  color: white;
  transition: all 0.35s;
  margin-top: 5px;
}

.tabInput:checked + .tabLabel::after {
  transform: rotate(270deg);
}
.tabInput:checked ~ .tabContent {
  max-height: 500vh;
  padding: 1em;
}

.text {
  margin: 12px 0;
  font-size: 1.1rem;
  line-height: 1.5rem;
  color: #a2a2a2;
}

.tag {
  border: 1px solid white;
  padding: 6px 18px;
  border-radius: 20px;
  margin: 14px 18px;
  font-weight: bold;
  display: inline-block;
  cursor: default;
  transition: color .5s, border .5s;
}


.tag:hover {
  color: var(--color-shakers);
  border-color: var(--color-shakers);
}

.element {
  font-size: 1.1rem;
  line-height: 1.8rem;
  margin-bottom: 30px;
}

.element code {
  color: var(--color-code);
  background: rgba(255, 255, 255, .1);
  padding: 2px 7px;
  border-radius: 12px;
  margin: 0 3px;
  font-size: 1.0rem;
}

@media(max-width: 700px) {
  .tabs,
  .intro {
    width: calc(100% - 30px);
  }
  .tag {
    font-size: .9rem;
    margin: 6px 12px;
  }
}