.main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: calc(100% - 40px);
  margin: 20px auto;
}

.intro {
  color: white;
  text-align: center;
}

.progressText {
  font-size: 20px;
}

.successText {
  font-size: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  min-height: 60px;
}

.successTitle {
  color: var(--color-success);
}

.failureText {
  font-size: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  min-height: 60px;
}

.failureTitle {
  color: var(--color-error);
}

.resetLink {
  text-decoration: underline;
  cursor: pointer;
}