.group:first-of-type {
  margin-top: 0;
}

.group {
  margin: 20px 0;
  padding: 20px;
  border: 1px solid white;
  user-select: none;
}

.title {
  display: flex;
  align-items: center;
  color: white;
  margin: 0;
  cursor: pointer;
  user-select: none;
  font-weight: 500;
  font-size: 1.4rem;
}

.label {
  background-color: var(--color-success);
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 6px;
  border-radius: 12px;
  margin-left: 8px;
} 

.content {
  margin-top: 20px;
}