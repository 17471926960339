@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.contact {
  padding-top: 60px;
}

.title {
  text-align: center;
  color: white;
  width: calc(100% - 20px);
  max-width: 850px;
  margin: 0px auto 60px;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: .35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.robot {
  fill: white;
  height: 50px;
  margin-right: 20px;
}

.content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  padding: 0 20px;
}

.result {
  font-family: 'Press Start 2P', cursive;
  font-size: 12px;
  margin-left: 20px;
  color: var(--color-info);
}

.main {
  width: calc(100% - 20px);
  max-width: 650px;
}

.options {
  display: flex;
  margin: 20px 0 40px;
  align-items: center;
  justify-content: space-around;
}

.social {
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.resultRobot {
  width: 50px;
  height: 50px;
  transition: fill .8s;
  cursor: pointer;
  fill: var(--color-info);
}

.newsletter {
  width: calc(100% - 40px);
  max-width: 900px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 80px auto 20px;
  border: 2px solid white;
  padding: 20px 12px;
}

.mailbox {
  height: 80px;
  margin-right: 25px;
}

.intro {
  color: white;
  margin-top: 0;
  margin-bottom: 25px;
  font-size: 20px;
}

@media(max-width: 875px) {
  .intro {
    font-size: 1em;
  }
}

@media(max-width: 650px) {
  .newsletter {
    width: 100%;
    margin-bottom: 60px
  }
  .content {
    flex-direction: column;
  }
  .social {
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;
  }
}

@media(max-width: 450px) {
  .newsletter {
    flex-direction: column;
  }
  .mailbox {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .intro {
    font-size: 1.1em;
  }
}

@media(max-width: 375px) {
  .social {
    padding: 0 10px;
  }
}