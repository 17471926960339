.slider {
  -webkit-appearance: none;
  width: 100%;
  margin: 5.5px 0;
  background: transparent;
}
.slider:focus {
  outline: none;
}
.slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 9px;
  cursor: pointer;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
  background: #ff4500;
  border-radius: 1px;
  border: 0px solid rgba(255, 69, 0, 0.1);
  border-radius: 10px;
}
.slider::-webkit-slider-thumb {
  box-shadow: 1px 1px 0.1px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(13, 13, 13, 0.1);
  border: 1px solid #000000;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5.5px;
}
.slider:focus::-webkit-slider-runnable-track {
  background: #ff8f66;
}
.slider::-moz-range-track {
  width: 100%;
  height: 9px;
  cursor: pointer;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
  background: #ff4500;
  border: 0px solid rgba(255, 69, 0, 0.1);
  border-radius: 10px;
}
.slider::-moz-range-thumb {
  box-shadow: 1px 1px 0.1px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(13, 13, 13, 0.1);
  border: 1px solid #000000;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  background: #ffffff;
  cursor: pointer;
}
.slider::-ms-track {
  width: 100%;
  height: 9px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
.slider::-ms-fill-lower {
  background: #992900;
  border: 0px solid rgba(255, 69, 0, 0.1);
  border-radius: 2px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
}
.slider::-ms-fill-upper {
  background: #ff4500;
  border: 0px solid rgba(255, 69, 0, 0.1);
  border-radius: 2px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
}
.slider::-ms-thumb {
  box-shadow: 1px 1px 0.1px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(13, 13, 13, 0.1);
  border: 1px solid #000000;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  background: #ffffff;
  cursor: pointer;
  height: 9px;
}
.slider:focus::-ms-fill-lower {
  background: #ff4500;
}
.slider:focus::-ms-fill-upper {
  background: #ff8f66;
}