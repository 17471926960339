.content {
  border-right: 2px solid rgba(255, 255, 255, .8);
  padding-right: 3px;
}

.content[data-status='complete'] {
  animation: typedBorderEdge .5s infinite alternate linear;
}

@keyframes typedBorderEdge {
  from {
    border-right: 2px solid rgba(255, 255, 255, .8);
  }
  to {
    border-right: 2px solid rgba(255, 255, 255, .05);
  }
}