.main {
  width: 100%;
  min-width: 298px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(28deg, rgba(222,9,212,1) 0%, rgba(87,0,180,1) 50%);
}

.label {
  color: white;
  margin-left: 12px;
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
}

.switch input:checked + div {
  background-color: teal;
}