.cover {
  position: relative;
  min-width: 298px;
  width: 100%;
  height: 180px;
}

.image {
  height: 100%;
  position: absolute;
  top: 0;
}

.image[data-position="top"] {
  background: url(./resources/beach.jpg);
  filter: sepia(.7);
  z-index: 2;
  animation: takover 8s infinite;
}

.image[data-position="bottom"] {
  background: url(./resources/beach.jpg);
  width: 100%;
}

@keyframes takover {
  0% {
    width: 0;
  }
  10% {
    width: 0;
  }
  40% {
    width: 100%;
  }
  60% {
    width: 100%;
  }
  90% {
    width: 0;
  }
  100% {
    width: 0;
  }
}