.content {
  display: flex;
  justify-content: space-around;
}

.controls {
  padding: 10px 20px;
  width: 90%;
  max-width: 360px;
  box-sizing: border-box;
}

.preview {
  padding: 60px 45px;
  border: 1px solid black;
  position: relative;
  box-sizing: border-box;
  min-height: 300px;
}

.preview::before {
  content: "PREVIEW AREA";
  position: absolute;
  top: 5px;
  left: 10px;
  font-weight: bold;
  color: var(--color-dark);
}

.previewBox {
  padding: 30px 12px;
}

.previewBoxLabel {
  border: 1px solid var(--color-dark);
  border-radius: 5px;
  padding: 35px 12px 10px;
  background-color: indianred;
  position: relative;
}

.previewBoxLabelCopy {
  position: absolute;
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;
  top: 0px;
  right: 0px;
  padding: 2px 5px;
  border-bottom-left-radius: 5px;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  transition: background .4s;
}

.previewBoxLabelCopy:hover {
  background-color: rgba(0, 0, 0, .2);
}

.previewBoxLabel code {
  font-size: 12px;
}

.slider {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 320px;
  margin: 0 auto 30px;
  width: 90%;
}

.sliderLabel {
  color: white;
}

.sliderValue {
  background: transparent;
  border: none;
  border-bottom: 2px solid white;
  color: white;
  outline: none;
  text-align: center;
  max-width: 50px;
  font-size: 18px;
}

.sliderValue::-webkit-outer-spin-button,
.sliderValue::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.sliderValue[type=number] {
  -moz-appearance: textfield;
}

.sliderRange {
  margin: 15px 0 0;
  width: 100%;
}

.sliderUnit {
  color: white;
  font-size: 18px;
  margin-left: 6px;
}

.color {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 320px;
  margin: 0 auto 30px;
  width: 90%;
}

.colorLabel {
  color: white;
}

.separator {
  border: none;
  border-bottom: 3px dashed;
  border-color: #A2A2A2;
  margin: 10px auto 25px;
  max-width: 200px;
}

.block {
  color: white;
  max-width: 650px;
  font-size: 18px;
  line-height: 28px;
  text-align: justify;
  margin: 30px auto 10px;
}

@media(max-width: 950px) {
  .content {
    flex-direction: column;
    align-items: center;
  }
}

@media(max-width: 550px) {
  .preview {
    width: calc(100% - 10px);
  }
}