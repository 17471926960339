.icon {
  width: 80px;
  cursor: pointer;
  transition: all .6s;
  padding: 10px;
}

.icon[data-enabled="disabled"] {
  cursor: not-allowed;
}

.icon[data-visibility='hidden'] {
  opacity: 0;
}

@media(max-width: 1300px) {
  .icon {
    width: 65px;
    padding: 7.5px;
  }
}

@media(max-width: 1060px) {
  .icon {
    width: 60px;
    padding: 4px;
  }
}

@media(max-width: 480px) {
  .icon {
    width: 40px;
    padding: 4px;
  }
}