#modal {
  display: none;
  width: 100%;
  margin: 0;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9999;
}

body.modal--active {
  overflow: hidden;
}

body.modal--active #modal {
  display: block;
}