.cover {
  width: 100%;
  min-width: 298px;
  height: 180px;
  box-sizing: border-box;
  background: #2f4f4f;
  display: flex;
  align-items: center;
  justify-content: center;
}

.note {
  width: 100%;
  height: calc(100% - 30px);
}

.path {
  stroke: white;
  fill: transparent;
  stroke-width: .25px;
  stroke-dasharray: 128;
  stroke-dashoffset: 128;
  animation: paintNote 8s infinite forwards;
}

@keyframes paintNote {
  to {
    stroke-dashoffset: 0;
  }
}