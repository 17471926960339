.bar {
  text-align: left;
  width: 750px;
  height: 25px;
  background-color: #3a3a3f;
  margin: 0 auto;
  float: none;
  border-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.bar--red,
.bar--yellow,
.bar--green {
  display: inline-block;
  border-radius: 100%;
  width: 15px;
  height: 15px;
  margin: 5px 3px;
}

.bar--red {
  background-color: var(--color-error);
}

.bar--yellow {
  background-color: var(--color-warning);
}

.bar--green {
  background-color: var(--color-success);
}

.screen {
  background-color: #33485E;
  width: 750px;
  height: 450px;
  border-radius: 5px;
  margin: 0 auto;
  padding: 20px 0 0 0;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  font-size: 15px;
  color: var(--color-success);
  font-family: monospace;
  overflow-y: scroll;
}

.content {
  text-align: left;
  position: static;
  margin: 0 20px 6px;
  word-break: break-all;
}

.content.content--jorge {
  color: var(--color-warning);
}

.input {
  font-size: 15px;
  background: transparent;
  font-family: monospace;
  border: none;
  color: var(--color-success);
  outline: none;
  vertical-align: top;
  resize: none;
  width: calc(100% - 80px);
  padding: 0;
}

.link {
  color: var(--terminal-orange);
}


@media(max-width: 1320px) {
  .bar {
    width: 600px;
  }
  .screen {
    width: 600px;
    height: 350px;
  }
}

@media(max-width: 620px) {
  .bar {
    width: 450px;
  }
  .screen {
    width: 450px;
    height: 320px;
  }
  .screen {
    font-size: 12.5px;
  }
  .input {
    font-size: 12.5px;
    width: calc(100% - 60px);
  }
}

@media(max-width: 460px) {
  .bar {
    width: 350px;
  }
  .screen {
    width: 350px;
    height: 250px;
  }
  .input {
    min-height: 40px;
  }
}

@media(max-width: 365px) {
  .bar {
    width: 300px;
  }
  .screen {
    width: 300px;
  }
  .input {
    min-height: 60px;
  }
}

@media(max-width: 310px) {
  .terminal {
    display: none;
  }
}