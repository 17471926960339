.cover {
  width: 100%;
  min-width: 298px;
  height: 180px;
  background-color: black;
  position: relative;
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
}

.portal {
  border-radius: 100%;
  position: absolute;
  width: 20px;
  height: 80px;
  border: 6px solid #fff;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  z-index: 15;
}

.portal[data-portal="1"] {
  left: 30px;
  border-color: var(--color-error);
  box-shadow: 0 0 50px 5px var(--color-error);
  animation: portal 2.5s linear infinite;
}

.portal[data-portal="2"] {
  border-color: var(--color-info);
  right: 30px;
  box-shadow: 0 0 50px 5px var(--color-info);
  animation: portal 3s .5s linear infinite;
}

.area {
  position: absolute;
  top: 20px;
  left: 45px;
  right: 45px;
  bottom: 20px;
  overflow: hidden;
}

.ball {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: var(--color-error);
  z-index: 1;
  animation: ball 3s linear infinite;
}

@keyframes ball {
  0% {
    background: var(--color-error);
    left: -20px;
  }
  100% {
    background: var(--color-info);
    left: 100%;
  }
}

@keyframes portal {
  0% {
    transform: translatey(-50%);
  }
  50% {
    transform: translatey(-45%);
  }
}
