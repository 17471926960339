.content {
  max-width: 650px;
  margin: 0 auto;
  width: calc(100% - 40px);
}

.block {
  color: white;
  max-width: 650px;
  font-size: 18px;
  line-height: 28px;
  text-align: justify;
}

.block code {
  color: var(--color-code);
}

.block strong {
  color: var(--color-shakers);
}

.block a {
  color: var(--color-link);
  font-weight: bold;
}

.element {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  min-width: 1500px;
  width: 1500px;
  height: 500px;
  background-image: linear-gradient(to top, #30cfd0 0%, #330867 100%);
  position: relative;
}

@media(max-width: 1650px) {
  .header {
    transform: scale(0.8);
  }
}

@media(max-width: 1300px) {
  .header {
    transform: scale(0.6);
  }
}

@media(max-width: 980px) {
  .header {
    transform: scale(0.45);
  }
}

@media(max-width: 730px) {
  .header {
    transform: scale(0.35);
  }
}

@media(max-width: 560px) {
  .header {
    transform: scale(0.25);
  }
}

@media(max-width: 410px) {
  .header {
    transform: scale(0.2);
  }
}

@media(max-width: 330px) {
  .header {
    transform: scale(0.15);
  }
}

@media(max-width: 255px) {
  .header {
    transform: scale(0.10);
  }
}

.monitor {
  position: absolute;
}

.screen {
  border: 12px solid #474e5d;
  border-top-width: 20px;
  border-radius: 10px;
  width: 460px;
  height: 280px;
  background-image: linear-gradient(135deg, #282c34 0%, #282c34 90%, #1e2124 100%);
}

.screen:before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(255, 255, 255, .4);
  display: block;
  position: absolute;
  left: calc(50% - 4px);
  top: 4px;
}

.base {
  width: 60px;
  height: 50px;
  background: linear-gradient(180deg, #373e4d 0%, #373e4d 25%, #474e5d 30%, #474e5d 100%);
  position: absolute;
  left: calc(50% - 30px);
}

.base:before {
  content: "";
  position: absolute;
  top: 0;
  border-left: 6px solid transparent;
  border-right: 0px solid transparent;
  border-bottom: 50px solid #474e5d;
  left: -6px;
}

.base:after {
  content: "";
  position: absolute;
  top: 0;
  border-right: 6px solid transparent;
  border-left: 0px solid transparent;
  border-bottom: 50px solid #474e5d;
  right: -6px;
}

.foot {
  width: 160px;
  height: 20px;
  background: #474e5d;
  position: absolute;
  top: 360px;
  left: calc(50% - 80px);
  z-index: 2;
}

.shadow {
  width: 350px;
  height: 15px;
  position: absolute;
  left: calc(50% - 175px);
  top: 372px;
  z-index: 1;
  background: radial-gradient(ellipse at center, rgba(0,0,0,.2) 0%,rgba(0,0,0,.2) 53%,rgba(0,0,0,0) 55%,rgba(0,0,0,0) 100%);
}

.logo[data-type="react"] {
  width: 100px;
  height: 100px;
  position: absolute;
  top: calc(50% - 50px);
  left: 50px;
}

.logo[data-type="html"] {
  width: 100px;
  height: 100px;
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
}

.logo[data-type="css"] {
  width: 100px;
  height: 100px;
  position: absolute;
  top: calc(50% - 50px);
  right: 50px;
}

.logo[data-type="node"] {
  width: 100px;
  height: 100px;
  position: absolute;
  top: calc(50% - 50px);
  left: 50px;
}

.logo[data-type="java"] {
  width: 100px;
  height: 100px;
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
}

.logo[data-type="mongo"] {
  width: 100px;
  height: 100px;
  position: absolute;
  top: calc(50% - 50px);
  right: 50px;
}