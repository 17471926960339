.main {
  max-width: 650px;
  margin: 0 auto;
  width: calc(100% - 40px);
}

.art {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signature {
  font-family: 'Courier New', Courier, monospace;
  color: white;
  white-space: nowrap;
  font-size: 7px;
}

.block {
  color: white;
  max-width: 650px;
  font-size: 18px;
  line-height: 35px;
  text-align: justify;
}

.block code {
  color: var(--color-code);
}

@media(max-width: 600px) {
  .signature {
    font-size: 5px;
  }
}

@media(max-width: 350px) {
  .signature {
    font-size: 4px;
  }
}