.header {
  width: calc(100% - 80px);
  max-width: 600px;
  margin: 0 auto;
}

.title {
  color: white;
  font-size: 40px;
  text-align: center;
  letter-spacing: 1px;
  font-weight: 400;
  margin-top: 60px;
  margin-bottom: 40px;
  user-select: none;
}