.icon {
  width: 40px;
  height: 25px;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  position: fixed;
  top: 20px;
  margin: 0;
  right: 20px;
  z-index: 9999;
}

.icon span {
  box-sizing: border-box;
  display: block;
  position: absolute;
  height: 5px;
  width: 100%;
  background: white;
  border-radius: 5px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.icon[data-status="closed"] span {
  border: 1px solid var(--color-dark);
}

.icon span:nth-child(1) {
  top: 0px;
}

.icon span:nth-child(2),
.icon span:nth-child(3) {
  top: 10px;
}

.icon span:nth-child(4) {
  top: 20px;
}

.icon[data-status="open"] span:nth-child(1) {
  top: 10px;
  width: 0%;
  left: 50%;
}

.icon[data-status="open"] span:nth-child(2) {
  transform: rotate(45deg);
}

.icon[data-status="open"] span:nth-child(3) {
  transform: rotate(-45deg);
}

.icon[data-status="open"] span:nth-child(4) {
  top: 10px;
  width: 0%;
  left: 50%;
}

.icon[data-status="closed"] + .content {
  display: none;
}

.content {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--color-dark);
  transition: all 1s;
  z-index: 9998;
  display: flex;
  align-items: center;
  justify-content: center;
}

.layout {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 500px;
}

.link {
  width: 180px;
  height: 180px;
  box-sizing: border-box;
  padding: 20px;
  margin: 20px;
  border: 1px solid white;
  border-radius: 4px;
  color: white;
  fill: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-decoration: none;
  transition: all .5s;
}

.link:hover {
  border: 1px solid var(--color-link);
  color: var(--color-link);
  fill: var(--color-link);
  background: rgba(var(--color-link), .1);
}

.text {
  margin-bottom: 0;
}

@media(max-width: 600px) {
  .layout {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 250px;
  }
  .link {
    width: 100px;
    height: 100px;
    padding: 10px;
    margin: 10px;
  }
}