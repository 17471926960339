.main {
  padding-top: 60px;
}

.title {
  text-align: center;
  color: white;
  font-size: 180px;
  margin: 0;
}

.subtitle {
  text-align: center;
  color: white;
  margin: 0;
  font-size: 32px;
  font-weight: 400;
}

.avatar .ghostEye {
  fill: var(--color-dark);
}

.avatar .ghostMouth {
  fill: var(--color-dark);
}

.avatar .ghostBody {
  animation: ghost-float 3s linear infinite;
}

.links {
  display: flex;
  width: 90%;
  margin: 30px auto;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.link {
  width: 180px;
  height: 180px;
  box-sizing: border-box;
  padding: 20px;
  margin: 20px;
  border: 1px solid white;
  border-radius: 4px;
  color: white;
  fill: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-decoration: none;
  transition: all .5s;
}

.link:hover {
  border: 1px solid var(--color-info);
  color: var(--color-info);
  fill: var(--color-info);
  background: rgba(0, 191, 255, .1);
}

.link-text {
  margin-bottom: 0;
}

.ghost {
  width: 240px;
  height: 240px;
  padding: 0;
  box-sizing: border-box;
  background: transparent;
  cursor: pointer;
  margin: 0 auto;
}

.avatar {
  width: 100%;
  height: 100%;
}

@media(max-width: 600px) {
  .links {
    flex-direction: row;
    margin: 40px auto;
  }
  .link {
    width: 100px;
    height: 100px;
    padding: 10px;
    margin: 10px;
  }
}

@keyframes ghost-float {
  0%, 100% {
    transform: translateY(20px);
  }
  50% {
    transform: translateY(0px);
  }
}

@keyframes ghost-zoom {
  0%, 100% {
    transform: scale(1);
    transform-origin: center center;
  }
  50% {
    transform: scale(0.9);
    transform-origin: center center;
  }
}