.snack {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 3rem;
  text-transform: uppercase;
  max-width: 700px;
  width: 90%;
  padding: 36px 84px;
  margin: 120px auto 0;
  text-align: center;
  box-sizing: border-box;
}

.snack[data-type="warning"] {
  background: var(--color-warning);
  border: 12px solid rgba(0,0,0,.3);
}

.snack[data-type="error"] {
  background: var(--color-error);
  border: 12px solid rgba(0,0,0,.3);
}

.area[data-status="ko"] {
  display: none;
}

.video[data-status="ko"] {
  display: none;
}

.canvas[data-status="ko"] {
  display: none;
}

.area {
  margin: 100px auto 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.video {
  width: 90%;
  max-width: 600px;
  cursor: pointer;
}

.canvas {
  width: 90%;
  max-width: 600px;
}

.button {
  border: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 14px;
  border: 2px solid white;
  outline: none;
  cursor: pointer;
}

.button[data-transform="grayscale"] {
  background: linear-gradient(30deg, rgba(255,220,0,1) 0%, rgba(239,29,0,1) 10%, rgba(24,225,7,1) 20%, rgba(12,28,216,1) 30%, rgba(194,11,208,1) 40%, rgba(138,138,138,1) 50%, rgba(58,58,58,1) 75%, rgba(10,10,10,1) 100%);
}

.button[data-transform="darken"] {
  background: linear-gradient(30deg, rgba(241,30,9,1) 0%, rgba(175,20,20,1) 50%, rgba(81,12,6,1) 100%);
}

.button[data-transform="lighten"] {
  background: linear-gradient(30deg, rgba(81,12,6,1) 0%, rgba(175,20,20,1) 50%, rgba(241,30,9,1) 100%);
}

.button[data-transform="sepia"] {
  background: linear-gradient(45deg, rgba(8,77,189,1) 0%, rgba(208,196,160,1) 65%);
}

.button[data-transform="blur"] {
  background: radial-gradient(circle, rgba(0,29,255,1) 0%, rgba(0,179,255,1) 25%, rgba(9,183,231,1) 50%, rgba(12,228,180,1) 75%, rgba(12,228,129,1) 100%);
}

.button[data-transform="hue"] {
  background: linear-gradient(90deg, rgba(255,1,0,1) 0%, rgba(33,255,0,1) 25%, rgba(9,157,231,1) 50%, rgba(228,12,218,1) 75%, rgba(228,12,18,1) 100%);
}

.button[data-transform="invert"] {
  background: linear-gradient(30deg, rgba(233,0,255,1) 0%, rgba(233,0,255,1) 49%, rgba(61,228,12,1) 51%, rgba(61,228,12,1) 100%);
}

.button[data-transform="back"] {
  background: radial-gradient(circle, rgba(126,0,20,1) 0%, rgba(255,0,36,1) 100%);
}

@media(max-width: 600px) {
  .snack {
    font-size: 1.8rem;
  }
}