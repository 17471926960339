.main {
  max-width: 650px;
  margin: 0 auto;
  width: calc(100% - 40px);
}

.block {
  color: white;
  max-width: 650px;
  font-size: 18px;
  line-height: 28px;
  text-align: justify;
}

.error {
  color: rgba(255, 255, 255, .5);
  max-width: 650px;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
}

.result {
  color: white;
  max-width: 650px;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
}

.confidence {
  color: white;
  max-width: 650px;
  font-size: 25px;
  line-height: 35px;
  text-align: center;
}

.button {
  background: transparent;
  font-size: 72px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: block;
  margin: 20px auto;
  cursor: pointer;
  outline: none;
}

.button[data-status="idle"]:hover {
  animation: small-rotation 1s linear infinite;
}

.button[data-status="active"] {
  cursor: not-allowed;
  background: crimson;
  border: 1px solid crimson;
  animation: small-pulsation 1s linear infinite;
}

.block code {
  color: var(--color-code);
}

.block a {
  color: var(--color-link);
}

@keyframes small-rotation {
  0% {
    transform: rotate(0deg)
  }
  25% {
    transform: rotate(3deg)
  }
  75% {
    transform: rotate(-3deg)
  }
  100% {
    transform: rotate(0deg)
  }
}

@keyframes small-pulsation {
  0% {
    font-size: 72px;
  }
  25% {
    font-size: 60px;
  }
  75% {
    font-size: 84px;
  }
  100% {
    font-size: 72px;
  }
}