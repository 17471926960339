.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.board {
  margin-top: 60px;
  display: grid;
  width: 700px;
  height: 700px;
  grid-template-columns: repeat(4, 1fr);
  border: 3px solid white;
}

.room {
  position: relative;
  border: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar {
  width: 50px;
  height: 50px;
  fill: #ff22ff;
}

.controls {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lightbulb {
  width: 50px;
  height: 50px;
}

.room[data-light='on'] {
  background: radial-gradient(circle, rgba(251,238,63,.3) 0%, rgba(252,70,107,0) 60%);
}

.room[data-light='on'] .lightbulb {
  fill: var(--color-shakers);
}

.room[data-room="1"]::after,
.room[data-room="2"]::after,
.room[data-room="3"]::after,
.room[data-room="5"]::after,
.room[data-room="6"]::after,
.room[data-room="7"]::after,
.room[data-room="9"]::after,
.room[data-room="10"]::after,
.room[data-room="11"]::after,
.room[data-room="13"]::after,
.room[data-room="14"]::after,
.room[data-room="15"]::after {
  position: absolute;
  top: calc(50% - 30px);
  right: -12px;
  height: 60px;
  width: 22px;
  background-color: var(--color-dark);
  content: " ";
  z-index: 2;
}

.room[data-room="1"]::before,
.room[data-room="2"]::before,
.room[data-room="3"]::before,
.room[data-room="4"]::before,
.room[data-room="5"]::before,
.room[data-room="6"]::before,
.room[data-room="7"]::before,
.room[data-room="8"]::before,
.room[data-room="9"]::before,
.room[data-room="10"]::before,
.room[data-room="11"]::before,
.room[data-room="12"]::before {
  position: absolute;
  left: calc(50% - 30px);
  bottom: -12px;
  width: 60px;
  height: 22px;
  background-color: var(--color-dark);
  content: " ";
  z-index: 2;
}

.room[data-room="16"]::after {
  position: absolute;
  left: calc(50% - 30px);
  bottom: -12px;
  width: 60px;
  height: 22px;
  background-color: var(--color-shakers);
  content: "EXIT";
  text-align: center;
  color: var(--color-dark);
  font-weight: bold;
  z-index: 2;
}

.notification {
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 1.3rem;
  margin: 10px 0;
  padding: 24px 20px;
  color: white;
}

.notification[data-status='completed'][data-result='success'] {
  color: var(--color-success);
}

.notification[data-status='completed'][data-result='failure'] {
  color: var(--color-warning);
}

.arrows {
  display: flex;
  align-items: center;
}

.vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.arrow {
  background: transparent;
  border: 2px solid white;
  border-radius: 5px;
  color: white;
  height: 40px;
  line-height: 0px;
  width: 40px;
  font-size: 20px;
  text-align: center;
  margin: 10px 3px;
  transition: all .3s;
  cursor: pointer;
}

.arrow:hover {
  background-color: rgba(255, 255, 255, .2);
}

.rulesBlock {
  color: white;
  max-width: 650px;
  font-size: 18px;
  line-height: 28px;
  text-align: justify;
}

@media(max-width: 990px) {
  .main {
    flex-direction: column;
  }
  .controls {
    margin-top: 35px;
  }
}

@media(max-width: 780px) {
  .board {
    width: 550px;
    height: 550px;
  }
  
  .avatar {
    width: 40px;
    height: 40px;
  }
  
  .lightbulb {
    width: 40px;
    height: 40px;
  }
}

@media(max-width: 600px) {
  .board {
    width: 420px;
    height: 420px;
  }
  
  .avatar {
    width: 31px;
    height: 31px;
  }
  
  .lightbulb {
    width: 31px;
    height: 31px;
  }
}

@media(max-width: 460px) {
  .board {
    width: 90vmin;
    height: 90vmin;
  }
  
  .avatar {
    width: 24px;
    height: 24px;
  }
  
  .lightbulb {
    width: 24px;
    height: 24px;
  }
  .board {
    border: 1.5px solid white;
  }
  
  .room {
    border: 1px solid white;
  }
  .room[data-room="1"]::after,
  .room[data-room="2"]::after,
  .room[data-room="3"]::after,
  .room[data-room="5"]::after,
  .room[data-room="6"]::after,
  .room[data-room="7"]::after,
  .room[data-room="9"]::after,
  .room[data-room="10"]::after,
  .room[data-room="11"]::after,
  .room[data-room="13"]::after,
  .room[data-room="14"]::after,
  .room[data-room="15"]::after {
    top: calc(50% - 18px);
    right: -8px;
    height: 36px;
    width: 15px;
  }
  .room[data-room="1"]::before,
  .room[data-room="2"]::before,
  .room[data-room="3"]::before,
  .room[data-room="4"]::before,
  .room[data-room="5"]::before,
  .room[data-room="6"]::before,
  .room[data-room="7"]::before,
  .room[data-room="8"]::before,
  .room[data-room="9"]::before,
  .room[data-room="10"]::before,
  .room[data-room="11"]::before,
  .room[data-room="12"]::before {
    left: calc(50% - 18px);
    bottom: -8px;
    width: 36px;
    height: 15px;
  }
  
  .room[data-room="16"]::after {
    left: calc(50% - 18px);
    bottom: -8px;
    width: 36px;
    height: 15px;
    font-size: 12px;
  }
}