.main {
  position: relative;
  width: 100%;
  min-width: 298px;
  height: 180px;
  background: linear-gradient(to left, #4265d3, #2144a2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  margin: 0;
  font-family: monospace;
  text-transform: uppercase;
  color: white;
  text-align: center;
  max-width: calc(100% - 40px);
  font-size: 23px;
  font-weight: bold;
}