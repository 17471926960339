.main {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 30px;
}

.info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-right: 20px;
}

.icon {
  fill: white;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.job {
  color: white;
  font-size: 20px;
  margin: 0;
}

.button {
  background-color: transparent;
  color: white;
  border: 1px solid white;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
}

.button:disabled {
  border-color: rgba(255, 255, 255, .4);
  color: rgba(255, 255, 255, .4);
}

.button:not(:disabled):hover {
  border-color: var(--color-code);
  color: var(--color-code);
}