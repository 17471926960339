.main {
  max-width: 650px;
  width: 90%;
  margin: 0 auto;
}

.block {
  color: white;
  max-width: 650px;
  font-size: 18px;
  line-height: 28px;
  text-align: justify;
}

.block code {
  color: var(--color-code);
}

.sample {
  max-width: 800px;
  margin-bottom: 40px;
}

.container {
  position: relative;
  width: 100%;
  height: 500px;
  margin-bottom: 20px;
}

.image {
  height: 100%;
  position: absolute;
  top: 0;
  background-repeat: no-repeat;
}

.image[data-position="top"] {
  background: url(./resources/lights.jpg);
  z-index: 2;
}

.image[data-position="bottom"] {
  background: url(./resources/lights.jpg);
  filter: grayscale(.9);
  width: 100%;
}

.detail {
  display: flex;
  justify-content: space-around;
  margin: 40px auto 20px;
}

.value, .percentage {
  color: white;
  font-size: 20px;
  padding: 20px 32px;
  border: 1px solid white;
  border-radius: 4px;
  min-width: 180px;
  box-sizing: border-box;
}

@media(max-width: 440px) {
  .detail {
    flex-direction: column;
  }
  .value, .percentage {
    margin: 10px
  }
}