.main {
  width: calc(100% - 20px);
  max-width: 800px;
  margin: 0 auto;
}

.board {
  display: flex;
  flex-wrap: wrap;
  width: 360px;
  margin: 40px auto 30px;
}

.cell {
  box-sizing: border-box;
  border: 1px solid white;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center; 
  width: 120px;
  height: 120px;
  cursor: pointer;
}

.cellContent {
  width: 100%;
  height: 100%;
}

.cellContent[data-type="o"] {
  stroke: var(--color-code);
  fill: transparent;
  stroke-width: 12px;
}

.cellContent[data-type="x"] {
  stroke: var(--color-warning);
  stroke-width: 15px;
}

.controls {
  width: 360px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hint {
  text-transform: uppercase;
  font-size: 32px;
  margin: 32px 0 0;
}

.hint[data-result='p1'] {
  color: var(--color-warning);
}

.hint[data-result='p2'] {
  color: var(--color-code);
}

.hint[data-result='tie'] {
  color: white;
}

@media(max-width: 500px) {
  .board {
    width: 240px;
  }
  .controls {
    width: 240px;
  }
  .cell {
    width: 80px;
    height: 80px;
  }
}