.main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 298px;
  height: 180px;
  background: #222;
  overflow: hidden;
}

.pulse {
  text-align: center;
  color: white;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 60px;
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: rgba(158, 106, 143, 1);
  cursor: pointer;
  box-shadow: 0 0 0 rgba(158, 106, 143, 0.4);
  animation: pulse 3s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(158, 106, 143, 0.4);
  }
  70% {
    box-shadow: 0 0 0 150px rgba(158, 106, 143, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(158, 106, 143, 0);
  }
}