.credit {
  background-color: black;
  color: white;
  text-decoration: none;
  padding: 4px 6px;
  font-family:  -apple-system, BlinkMacSystemFont, "San Francisco", "Helvetica Neue", Helvetica, Ubuntu, Roboto, Noto, "Segoe UI", Arial, sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.2;
  display: inline-block;
  border-radius: 3px;
}

.content {
  display: inline-block;
  padding: 2px 3px
}

.logo {
  height: 12px;
  width: auto;
  position: relative;
  vertical-align: middle;
  top: -2px;
  fill: white;
}

.author {
  display: inline-block;
  padding: 2px 3px;
}