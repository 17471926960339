.options {
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.restart {
  border: 1px solid white;
  border-radius: 4px;
  background: transparent;
  color: white;
  padding: 12px 40px;
  font-size: 18px;
  text-transform: uppercase;
  display: inline-block;
  cursor: pointer;
  transition: all 1s;
  box-sizing: border-box;
  text-decoration: none;
  user-select: none;
  margin: 20px;
}

.main {
  width: calc(100% - 20px);
  max-width: 600px;
  margin: 10px auto 0;
}

.streak {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.streakIntro {
  color: white;
}

.streakTitle {
  text-transform: uppercase;
  font-size: 28px;
  margin: 0;
  font-weight: bold;
  color: transparent;
  background: linear-gradient(
    90deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(255, 154, 0, 1) 10%,
    rgba(208, 222, 33, 1) 20%,
    rgba(79, 220, 74, 1) 30%,
    rgba(63, 218, 216, 1) 40%,
    rgba(47, 201, 226, 1) 50%,
    rgba(28, 127, 238, 1) 60%,
    rgba(95, 21, 242, 1) 70%,
    rgba(186, 12, 248, 1) 80%,
    rgba(251, 7, 217, 1) 90%,
    rgba(255, 0, 0, 1) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  margin-bottom: 30px;
}

.streakScore {
  font-size: 60px;
  line-height: 60px;
}

.keyboard {
  width: calc(100% - 20px);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row {
  display: flex;
  align-items: center;
}

.key {
  cursor: pointer;
  margin: 3px;
  border: none;
  border-radius: 2px;
  box-sizing: content-box;
  font-size: 24px;
  font-weight: bold;
  padding: 8px;
  text-transform: uppercase;
  min-width: 30px;
  min-height: 40px;
  line-height: 40px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.key[data-status='correct'] {
  background: var(--color-success);
}

.key[data-status='misplaced'] {
  background: var(--color-shakers);
}

.key[data-status='incorrect'] {
  opacity: .3;
}

.icon {
  stroke: black;
  stroke-width: 2;
  fill: transparent;
}

.attempts {
  margin-bottom: 40px;
}

.attempt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 300px;
  margin: 10px auto;
}

.attempt[data-vibration='shake'] {
  animation: shake .2s infinite;
}

.letter {
  border: 1px solid white;
  width: 50px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fillBackground .6s;
  animation-fill-mode: forwards;
}

.attempt > div:nth-of-type(1) {
  animation-delay: .2s;
}

.attempt > div:nth-of-type(2) {
  animation-delay: .4s;
}

.attempt > div:nth-of-type(3) {
  animation-delay: .6s;
}

.attempt > div:nth-of-type(4) {
  animation-delay: .8s;
}

.attempt > div:nth-of-type(5) {
  animation-delay: 1s;
}

.letter[data-status='correct'] {
  --background-color: 50, 215, 75;
}

.letter[data-status='misplaced'] {
  --background-color: 229, 243, 0;
}

.letter[data-status='incorrect'] {
  --background-color: 245, 97, 87;
}

.letterContent {
  color: white;
  text-transform: uppercase;
  font-size: 32px;
}

.gameOverPanel {
  border: 1px solid white;
  border-radius: 3px;
  padding: 20px;
}

.gameOverTitle {
  margin: 0 0 20px;
  text-align: center;
  color: white;
  font-size: 20px;
  text-transform: uppercase;
}

.gameOverButtons {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.gameOverButton {
  border: 1px solid white;
  border-radius: 4px;
  background: transparent;
  color: white;
  padding: 12px 20px;
  font-size: 18px;
  text-transform: uppercase;
  display: inline-block;
  cursor: pointer;
  transition: all 1s;
  box-sizing: border-box;
  text-decoration: none;
  user-select: none;
  margin: 20px 0;
}

.gameOverButton:hover {
  background: rgba(255, 255, 255, 0.1);
}

.modalTitle {
  text-align: center;
}

.modalContent {
  padding: 10px 40px 25px;
}

.modalText {
  margin-top: 0;
}

.modalButton {
  border: 1px solid white;
  border-radius: 4px;
  background: transparent;
  color: white;
  padding: 6px 12px;
  text-transform: uppercase;
  display: inline-block;
  cursor: pointer;
  transition: all 1s;
  box-sizing: border-box;
  text-decoration: none;
  user-select: none;
  margin: 20px 0;

}

@media(max-width: 600px) {
  .key {
    font-size: 18px;
    font-weight: bold;
    padding: 5px;
    text-transform: uppercase;
    min-width: 16px;
    min-height: 24px;
    line-height: 24px;
  }
  .gameOverButtons {
    flex-direction: column;
  }
  .gameOverButton {
    margin: 10px 0;
  }
  .streakTitle {
    font-size: 22px;
    margin-bottom: 12px;
  }
  .streakScore {
    font-size: 48px;
    line-height: 48px;
  }
}

@keyframes shake {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-4px, 0);
  }
  50% {
    transform: translate(0, 0);
  }
  75% {
    transform: translate(4px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes fillBackground {
  0% {
    background-color: transparent;
  }

  50% {
    background-color: rgba(var(--background-color), .15);
  }

  100% {
    background-color: rgba(var(--background-color), .25);
  }
  
}