.header {
  box-sizing: border-box;
  width: 100%;
  padding: 0 100px 0 20px;
  position: relative;
  border-bottom: 1px solid white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 80px;
}

.info {
  display: flex;
  align-items: center;
}

.exit {
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: all .3s;
  fill: white;
  margin: 0 15px;
}

.exit:hover {
  transform: scale(1.1);
  fill: var(--color-error);
}

.title {
  color: white;
  font-size: 25px;
  line-height: 40px;
  letter-spacing: 1px;
  font-weight: 400;
  margin-top: 14px;
  margin-bottom: 14px;
  text-decoration: underline;
  word-break: break-word;
  user-select: none;
}

.actions {
  margin-right: 40px;
  display: flex;
}

.icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: all .3s;
  fill: white;
  margin: 0 15px;
}

.icon:hover {
  transform: scale(1.1);
}

.icon:hover path {
  fill: var(--color-shakers);
}

.modalTitle {
  text-align: center;
}

.modalContent {
  padding: 10px 40px 25px;
}

.options {
  box-sizing: border-box;
  width: calc(100% - 80px);
  border: 1px solid white;
  margin: 0 auto;
  padding: 20px 10px;
  border-radius: 4px;
  position: absolute;
  min-height: 120px;
  background: var(--color-dark);
  top: 110px;
  right: 40px;
  z-index: 999;
}

.options::before {
  content: "⚙";
  color: white;
  z-index: 2;
  position: absolute;
  top: -41px;
  left: -12px;
  font-size: 45px;
  background: var(--color-dark);
  padding: 0px 12px 3px 0px;
  display: inline-block;
}

.options::after {
  content: "Game Settings";
  color: white;
  z-index: 2;
  position: absolute;
  top: -25px;
  left: 35px;
  font-size: 16px;
  letter-spacing: 0.9px;
}

.noOptions {
  margin: 0;
  color: #aaa;
  font-family: monospace;
  padding: 20px;
  letter-spacing: .5px;
  font-size: 1.2rem;
  font-style: italic;
}

.optionsBackground {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; 
  background: rgba(40, 44, 52, .8);
  z-index: 998;
}

.option {
  display: flex;
  align-items: center;
}

.optionButton {
  padding: 12px 26px;
  background: transparent;
  border: 1px solid black;
  border-radius: 5px;
  margin: 20px;
  font-size: .8em;
  text-transform: uppercase;
  cursor: pointer;
  transition: background .65s;
  outline: none;
  border-color: white;
  color: white;
  box-sizing: border-box;
  text-decoration: none;
  user-select: none;
}

.optionDescription {
  margin-left: 10px;
  color: white;
}

.optionButton:hover {
  background: rgba(255, 255, 255, .15);
}

.optionButton:disabled {
  cursor: not-allowed;
  opacity: .5;
}

@media(max-width: 600px) {
  .header {
    padding: 0 100px 0 30px;
  }
  .actions {
    margin-right: 30px;
  }
  .title {
    font-size: 22px;
    line-height: 24px;
  }
  .icon {
    width: 20px;
    height: 20px;
    margin: 0 8px;
  }
}

@media(max-width: 420px) {
  .header {
    padding: 0 80px 0 10px;
  }
  .actions {
    margin-right: 18px;
  }
  .title {
    font-size: 18px;
  }
  .icon {
    width: 15px;
    height: 15px;
    margin: 0 8px;
  }
}