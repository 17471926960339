.main {
  width: 800px;
  max-width: calc(100% - 40px);
  margin: 30px auto;
  z-index: 1;
}

.options {
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.board {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 9px;
  width: 400px;
  height: 400px;
  margin: 20px auto;
  perspective: 1000px;
}

.board[data-difficulty="hard"] {
  grid-template-columns: repeat(7, 1fr);
}

.tile {
  width: 100%;
  height: 100%;
  position: relative;
  transition: transform 0.6s ease;
  transform-style: preserve-3d;
  border: 1px solid #ddd;
  cursor: pointer;
}

.tile::before,
.tile::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transition: background-color 0.3s ease;
}

.tile::before {
  background-color: white;
  transform: rotateY(0deg);
}

.tile::after {
  background-color: black;
  transform: rotateY(180deg);
}

.tile[data-tile="black"] {
  transform: rotateY(180deg);
}

.tile:hover {
  box-shadow: 0 0 3px 3px rgba(255, 255, 255, 0.5);
}

.modalTitle {
  text-align: center;
}

.modalContent {
  padding: 10px 40px 25px;
}

.modalActions {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.button {
  padding: 12px 26px;
  background: transparent;
  border: 1px solid black;
  border-radius: 5px;
  margin: 20px;
  font-size: .8em;
  text-transform: uppercase;
  cursor: pointer;
  transition: background .65s;
  outline: none;
  border-color: white;
  color: white;
  box-sizing: border-box;
  text-decoration: none;
  user-select: none;
}

.button:hover {
  background: rgba(255, 255, 255, .15);
}

.button:disabled {
  cursor: not-allowed;
  opacity: .5;
}

@media(max-width: 500px) {
  .board {
    gap: 7px;
    width: 320px;
    height: 320px;
  }
}

@media(max-width: 400px) {
  .board {
    gap: 7px;
    width: 250px;
    height: 250px;
  }
}