.main {
  max-width: 650px;
  margin: 0 auto;
  width: calc(100% - 40px);
}

.block {
  color: white;
  max-width: 650px;
  font-size: 18px;
  line-height: 28px;
  text-align: justify;
}

.block code {
  color: var(--color-code);
}

.block strong {
  color: var(--color-shakers);
}

.input {
  background: transparent;
  border: 1px solid white;
  border-radius: 8px;
  padding: 25px 12px;
  width: 100%;
  box-sizing: border-box;
  color: white;
  font-size: 18px;
}

.result {
  max-width: 650px;
  font-size: 18px;
  line-height: 28px;
  border: 1px solid var(--color-info);
  color: var(--color-info);
  border-radius: 8px;
  padding: 25px 12px;
  text-align: center;
}