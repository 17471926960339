.main {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.particles {
  position: fixed;
  top: 0;
  z-index: -1;
  background-color: var(--color-dark);
}

.particles canvas {
  opacity: .35;
}

.terminal {
  z-index: 2;
}

.details {
  margin: 30px;
  z-index: 2;
}

.intro {
  color: white;
  font-size: 2.5rem;
  text-align: center;
}

.text {
  margin: 0 0 10px 0;
}

.social {
  margin: 60px auto 10px;
  text-align: center;
}

@media(max-width: 1490px) {
  .intro {
    font-size: 2rem;
  }
}

@media(max-width: 1125px) {
  .main {
    flex-direction: column;
  }
}

@media(max-width: 620px) {
  .details {
    margin: 30px 10px;
  }
  .intro {
    font-size: 1.8rem;
  }
}

@media(max-width: 480px) {
  .intro {
    font-size: 1.5rem;
  }
}

@media(max-width: 480px) {
  .intro {
    font-size: 1.2rem;
  }
}

@media(max-width: 350px) {
  .intro {
    font-size: 1rem;
  }
}